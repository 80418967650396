import { Component, OnInit, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

import * as d3 from 'd3';

import { SvgUtilsService } from '../svg-utils.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {
  ngOnInit() {}


	@Input() value: number
	@Input() isSet: boolean
	@Output() valueChange = new EventEmitter<number>();
	@Output() valueSelected = new EventEmitter<number>();

	@ViewChild('svgPane') svgPane: ElementRef
	@ViewChild('sliderGroup') sliderGroupRef: ElementRef
	@ViewChild('trackOverlay') trackOverlayRef: ElementRef
	@ViewChild('coloredInset') coloredInsetRef: ElementRef
	@ViewChild('handle') handleRef: ElementRef

	// set to true after view inits
	private isViewInit: boolean = false;

	// the SVG elements
	gradient: {id: string; stops: Array<{offset: string; stopColor: string}>; cx; cy; r;} = null
	gradientURL: string;


	private handleRadius = 20;
	width: number
	sliderWidth: number
	// height should be 2x handle radius plus some padding
	// was 3.2 before I removed the text from the slider
	height = 2.2 * this.handleRadius;
	private sliderGroup
	private trackOverlay
	private coloredInset
	private handle
	private xScale
	// margin must be 2px greater than handle radius to allow for stroke
	private margin = {right: this.handleRadius + 2,
		left: this.handleRadius + 2};

	public mEase = d3.easeBackOut.overshoot(1.1)

  constructor(public svgUtils: SvgUtilsService) {
    // console.log('Hello Slider Component');
		let id =  Math.round(Math.random() * 100000)
		this.gradient = {id: "gradient_" + id, stops: null,
			cx: '40%', cy: '40%', r: '70%',
		}
		this.gradientURL =  "url('#" + this.gradient.id + "')"
  }

	@HostListener('window:resize') windowResize(){
		this.setSize()
	}

	ngOnChanges(changes){
		// console.log( changes )
		let color = this.svgUtils.getColor(50)
		// let radius = 70+'%' // not used
		let brightspot = 1
		let darkspot = 1.75
		if(changes.value && changes.value.currentValue !== undefined){
			// let colorval = changes.value.currentValue
			let colorval = 100
			color = this.svgUtils
			.getColor(colorval)
			this.gradient.cx = d3.scaleLinear().range([30,40]).domain([0,100])(colorval) + '%'
			this.gradient.cy = d3.scaleLinear().range([40,30]).domain([0,100])(colorval) + '%'
			this.gradient.r = d3.scaleLinear().range([80,50]).domain([0,100])(colorval) + '%'
		brightspot = d3.scaleLinear().range([1,2]).domain([0,100])(colorval)
		darkspot = d3.scaleLinear().range([1.75,1.3]).domain([0,100])(colorval)
		}
		this.gradient.stops = [
			{ offset:   '0%', stopColor: d3.rgb(color).brighter(brightspot) },
			{ offset:  '50%', stopColor: color },
			{ offset: '100%', stopColor: d3.rgb(color).darker(darkspot) }
		]
		// this.setSize();
		if( this.coloredInset && this.handle ){
			this.handle.attr("cx", this.xScale(this.value))
			let colorval = this.isSet ? this.svgUtils.getColor(this.value): "none"
			this.coloredInset.transition().duration(150).ease(this.mEase)
				.attr("x2", this.xScale(this.value))
				.style("stroke", colorval)
		}
	}

	ngAfterViewInit(){
		this.isViewInit=true;
		this.xScale = d3.scaleLinear().domain([0, 100]).clamp(true);
		this.setSize()
		this.sliderGroup = d3.select(this.sliderGroupRef.nativeElement)
		this.trackOverlay = d3.select(this.trackOverlayRef.nativeElement)
		this.coloredInset = d3.select(this.coloredInsetRef.nativeElement)
		this.handle = d3.select(this.handleRef.nativeElement)

		this.sliderGroup.attr("transform",
			"translate(" + this.margin.left + "," + (1.1 * this.handleRadius ) + ")");
		this.trackOverlay.call(d3.drag()
			.on("start drag", (event) => {
					this.clampedSetting(this.xScale.invert(event.x)); })
			.on("end", (event) => {
					this.clampedEmitter(this.xScale.invert(event.x)); })
			);

		if(this.isSet){
			this.coloredInset.style("stroke", this.svgUtils.getColor(this.value))
		}
		this.handle.call(d3.drag()
			.on("drag", (event) => { 
				this.clampedSetting(this.xScale.invert(event.x)); })
			.on("end", (event) => {
				this.clampedEmitter(this.xScale.invert(event.x)); })
		);
		this.trackOverlay.call(d3.drag()
			.on("end", (event) => {
				this.clampedSetting(this.xScale.invert(event.x)); 
				this.clampedEmitter(this.xScale.invert(event.x));
			})
		);
	}


	// called initially and on resize to set all the sizes
	// of all elements
	private setSize(): void{
		if( this.isViewInit ){
			setTimeout(() => {
				this.width = this.svgPane.nativeElement.offsetWidth
				this.sliderWidth = this.width - this.margin.left - this.margin.right;
				this.xScale.range([0, this.sliderWidth])
				if(this.isSet){
					this.coloredInset.attr("x2", this.xScale(this.value))
				}
				this.handle.attr("cx", this.xScale(this.value))
			},1)
		}
	}


	private clampedEmitter(h: number){
		let closest  = Math.min(Math.max(this.xScale.range()[0], h),
			this.xScale.range()[1]);
		this.valueSelected.emit(Math.round(closest));
	}

	private clampedSetting(h: number) {
		let closest  = Math.min(Math.max(this.xScale.range()[0], h),
			this.xScale.range()[1]);

		this.valueChange.emit(Math.round(closest));

		this.coloredInset.transition().duration(150).ease(this.mEase)
			.attr("x2", this.xScale(closest))
			.style("stroke", this.svgUtils.getColor(closest))
		this.handle.transition().duration(150).ease(this.mEase)
			.attr("cx", this.xScale(closest))
	}

}
