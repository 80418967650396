
import { Component, OnInit, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import * as d3 from 'd3';

import { SvgUtilsService } from "../svg-utils.service";
import { ConstantsService } from "../constants.service";

import { MoodspacePoint } from '../../interfaces/moodspacePoint';
import { SvgGradient } from '../../interfaces/svgGradient';

@Component({
  selector: 'app-svg-chart',
  templateUrl: './svg-chart.component.html',
  styleUrls: ['./svg-chart.component.scss'],
})
export class SvgChartComponent implements OnInit {
	ngOnInit(){}

	@Input() points: Array<MoodspacePoint>;
	@Output() pointSelected = new EventEmitter<MoodspacePoint>();

	@Input() isInput: boolean
	@Input() inputMood
	@Output() locationAt = new EventEmitter<any>(); // the current point location
	@Output() locationSelected = new EventEmitter<any>();
	@ViewChild('inputPoint') inputPointRef: ElementRef
	inputPoint
	inputGradientStops = []

	radiusScale = d3.scaleLinear().clamp(true).range([7, 15])
	lookbackDays = 9 // the number of days back in time we expect to be looking
	// sets the time endpoint for the minimum radius
	circumplexAxis // an object whose values are the terms for the axis endpoints

	@ViewChild('svgObj') svgElem: ElementRef

	// public backgroundFill: string = "#444444"
	// public backgroundStroke: string = "#222222" 
	public backgroundGradientStops = [
		{ offset:   '0%', stopColor: "#EFEFF4" },
		{ offset:  '90%', stopColor: "#DDDDE4" },
		// { offset:  '50%', stopColor: "#BBBBCC" },
		{ offset:  '95%', stopColor: "#555564" },
		{ offset:  '98%', stopColor: "#444444" },
		{ offset: '100%', stopColor: "#222255" }
	];


	public mEase = d3.easeBackOut.overshoot(1.1)

	gradients: Array<SvgGradient> = []
	axisEndpoints: Array<MoodspacePoint> = [
			{ id:"endpoint1", affect:  110, activation:    0, radius: 5, isAxisPoint: true},
			{ id:"endpoint2", affect:    0, activation:  110, radius: 5, isAxisPoint: true},
			{ id:"endpoint3", affect: -110, activation:    0, radius: 5, isAxisPoint: true},
			{ id:"endpoint4", affect:    0, activation: -110, radius: 5, isAxisPoint: true},
		] 

	constructor(
		public svgUtils: SvgUtilsService,
		constants: ConstantsService
	) {
    console.log('Hello SvgChart Component');
		// initialize gradients for input point, if one exists
		// let color = this.svgUtils.getSpaceColor(0,0)
		this.inputGradientStops = this.svgUtils.getGradientStopsArray({affect:0, activation:0}) 
		this.circumplexAxis = constants.getCircumplexAxis()
		// console.log( this.circumplexAxis )
	}

	ngAfterViewInit(){
		// console.log( "Circumplex view init" )
		if(this.isInput){
			this.inputPoint = d3.select(this.inputPointRef.nativeElement)
				.attr('r',15)
				.call(d3.drag()
					.on("start", () => {
						this.inputPoint.transition().duration(250).ease(this.mEase)
							.attr('r', 26)
					})
					.on("drag", (event) => { this.clampedSetting(event) })
					.on("end", (event) => { this.clampedEmitter(event) })
				)
			// set the mood input point to the correct position (if it is not the default)
			let startX = this.inputMood && this.inputMood.affect ? this.inputMood.affect : 0
			let startY = this.inputMood && this.inputMood.activation ? -1 * this.inputMood.activation : 0
			let newPos = this.clamp(startX, startY)
			if(this.inputPoint){
				this.inputPoint.transition().duration(150).ease(this.mEase)
					.attr("cx", newPos.x) 
					.attr("cy", newPos.y)
			}
			// invert y because SVG space is inverted
			newPos.y = -1 * newPos.y
			this.inputGradientStops = this.svgUtils.getGradientStopsArray({affect: newPos.x, activation: newPos.y})
		}
	}

	ngOnChanges(changes){
		// console.warn( "changes", changes)
		if(changes.hasOwnProperty('inputMood')){
			// console.log( "changes", changes.inputMood)
			if(changes.inputMood.currentValue === null) return
			if(!changes.inputMood.firstChange){
				let svgY = -1 * changes.inputMood.currentValue.activation
				this.clampedSetting({x:changes.inputMood.currentValue.affect, y: svgY})
			}
		}

		// *** IMPORTANT ***
		// the following code allows the chart to display multiple points
		// It is legacy, from the Moodus version, and may not work with 
		// the current system
		// *** *** *** *** *** *** *** ***
		// let newPoints =[]
		// if(changes.points){
		// 	newPoints = changes.points.currentValue.map( point => {
		// 		point.selected = false
		// 		// set up mock data 
		// 		if(point.usk && point.usk === "mock"){
		// 			point.radius = 15
		// 			point.isAxisPoint = true
		// 		}
		// 		return Object.assign({}, point)
		// 	})
		// 	// let extent = d3.extent(newPoints, (pt) => pt.localTimestamp)
		// 	let maxTS = d3.max(newPoints, (pt) => pt.localTimestamp)
		// 	let extent = [maxTS - this.lookbackDays*24*3600*1000, maxTS]

		// 	this.radiusScale.domain(extent)
		// }
		// // Reverse input points, so most recent is last in the display
		// // last displayed is on the top.
		// this.points = this.axisEndpoints.concat(newPoints.reverse())
		// this.gradients = this.points
		// 	.map( (point, index) => {
		// 		// console.log( point )
		// 		// add attributes to points which are needed for display
		// 		point.fill = "url('#gradient_" + point.id + "')"
		// 		point.cx = point.affect 
		// 		point.cy = -1 * point.activation // because svg y coordinates are inverted
		// 		if(! point.isAxisPoint)
		// 			point.radius = this.radiusScale(point.localTimestamp)
		// 		// now generate the gradient object
		// 		let grad = {
		// 			cx: '40%', cy: '40%', r: '70%', id: "gradient_" + point.id,
		// 			stops: this.svgUtils.getGradientStopsArray(point)
		// 		} as SvgGradient
		// 		return grad
		// 	})
	}

	/**
	 * selectMood
	 * Called when one of the points is clicked on
	 * */
	selectMood(mood){
		// console.log( "select mood called", mood )
		if(mood.isAxisPoint) return
		this.points.forEach(point => point.selected = false)
		mood.selected = true
		this.pointSelected.emit(mood)
	}

	// adjust size on window resize
	@HostListener('window:resize') windowResize(){
		// console.log( "resize" )
		// let dims = this.svgElem.nativeElement
		// 	.parentNode.getBoundingClientRect() 
		// if(!dims) dims = {width: 400, height: 400}
	}

	
	/** 
	 * clamp
	 * @param x
	 * @param y
	 * Return {x,y} where the values are clamped to within the 
	 * (100) unit circle
	 * */
	private clamp(x,y){
		if(Math.sqrt(x*x + y*y)>100){
			// x must be greater than zero for the if condition above 
			// to hold, given that values are clamped
			let alpha = Math.atan(y/x)
			let mult = x>0 ? 100 : -100
			y = Math.sin(alpha) * mult
			x = Math.cos(alpha) * mult
		}
		return {x,y}
	}

	/**
	 * clampedSettings
	 * @param dragEvent
	 * moves the point and updates colors/text
	 * */
	clampedSetting(dragEvent){
		let newPos = this.clamp(dragEvent.x, dragEvent.y)
		if(this.inputPoint){
			this.inputPoint.transition().duration(150).ease(this.mEase)
				.attr("cx", newPos.x) 
				.attr("cy", newPos.y)
		}
		// invert y because SVG space is inverted
		newPos.y = -1 * newPos.y
		// let color = this.svgUtils.getSpaceColor(newPos.x, newPos.y)
		this.inputGradientStops = this.svgUtils.getGradientStopsArray({affect: newPos.x, activation: newPos.y})
		this.locationAt.emit(newPos)
	}

	/**
	 * clampedEmitter
	 * @returns a point in MOOD space
	 * converts the mouse event from svg to mood coordinates 
	 * and returns it.
	 * */
	clampedEmitter(dragEvent){
		// console.log( "svg chart emits drag event" )
		let newPos = this.clamp(dragEvent.x, dragEvent.y)
		this.inputPoint.transition().duration(250).ease(this.mEase)
			.attr('r', 15)
		// invert y because SVG space is inverted
		newPos.y = -1 * newPos.y
		this.locationSelected.emit(newPos)
	}

}
