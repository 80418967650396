import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AlertController } from '@ionic/angular';
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';

// import { IonicPage, AlertController, NavController, NavParams } from 'ionic-angular';

import { LogService } from "../log.service";
import { ProfileService } from "../profile.service";
import { MessagingService } from "../messaging.service";

@Component({
  selector: 'app-history',
  templateUrl: './history.page.html',
  styleUrls: ['./history.page.scss'],
})
export class HistoryPage implements OnInit {
	ngOnInit() { }

	performance: number = 0
	displayPerformance: number = 0
	isData: boolean = false
	alertHack: boolean = false

	constructor(
		log: LogService,
		afAuth: AngularFireAuth,
		alertCtrl: AlertController,
		msgService: MessagingService, 
		profile: ProfileService
	) {
		let destroy$: Subject<boolean> = new Subject<boolean>();

		const logsub = log.responseRateSubject.subscribe(res => {
			if(res){
				// console.log( "response rate", res )
				this.performance = res
				this.displayPerformance = Math.round(res)
			} })

		profile.userSettings.subscribe(user => {
			if(! user ) return
			if(this.alertHack) return
			// ===   DEBUG   ===
			// let tmp = this.alertHack
			// console.log( "history alert hack", tmp )
			// === END DEBUG ===
			if(user.isFinished && !this.alertHack) {
				this.alertHack = true
				msgService.removeUserTokens(user.uid)
				// let msg = "You've finished the study. Thank you again for your participation."
				let msg = "Sie haben die Studie beendet. Herzlichen Dank für Ihre Teilnahme!"
				alertCtrl.create({
					// title: "Study complete",
					header: "Studie beendet",
					message: msg,
					buttons: [ {
						text: "OK",
						role: 'cancel',
						handler: () => {
							msgService.removeUserTokens(user.uid)
						}
					}]})
					.then(res => res.present())
			}
		})


		log.allEntriesSubject.pipe(takeUntil(destroy$)).subscribe(res => {
			// console.warn( "history log length:",res )
			this.isData = res && res.length > 0
			if(this.isData) destroy$.next(true)
		})

		afAuth.authState.subscribe(res => {
			if(!res){
				logsub.unsubscribe()
			}
		})
	}

  ngAfterViewInit() { }


}

