import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators} from "@angular/forms/";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";

import { Subject } from "rxjs";
import { debounceTime, takeUntil, switchMap, tap } from "rxjs/operators";

import { ProfileService } from "../profile.service";
import { LogService } from "../log.service";

@Component({
  selector: 'app-change-study',
  templateUrl: './change-study.page.html',
  styleUrls: ['./change-study.page.scss'],
})
export class ChangeStudyPage implements OnInit {

  ngOnInit() { console.warn("change study page init" ) }
	isShowStudyIDWarning: boolean = false; // toggles warning messages
	isSameStudyIDWarning: boolean = false; // toggles warning messages
	studyPurpose: string = ''
	@Input() doneButtonText: string = "Fertig" // text displayed in the "done" button at bottom of page

	firstName: string = ''
	lastName: string = ''

	private origStudyID: string
	private isStudyChanged: boolean
	private newStudyID: string


	private authObserver
	private newUserSubect = new Subject<string>(); // used to watch for account creation for new users

	settingsForm: FormGroup;

	constructor(
		public modalCtrl: ModalController,
		formBuilder: FormBuilder,
		afAuth: AngularFireAuth,
		public afStore: AngularFirestore,
		public profile: ProfileService,
		public log: LogService
	) {
		// let destroy$: Subject<boolean> = new Subject<boolean>();
		console.log( "hello change study page" )

		this.settingsForm = formBuilder.group({
			studyID: ['', Validators.required],
		})

		this.settingsForm.valueChanges.pipe(debounceTime(250)).subscribe(res => {
			this.isStudyChanged = false
			if(res.studyID !== ''){
				if(res.studyID === this.origStudyID) {
					this.isSameStudyIDWarning = true
					this.isShowStudyIDWarning = false
					this.settingsForm.controls['studyID'].setErrors({"invalidStudyID": true})
				} else {
					// Check if study ID is valid
					this.isSameStudyIDWarning= false
					this.log.getStudyFromID(res.studyID).then((res) => {
						// console.log( res )
						// we sometimes get timing errors here, if current study
						// is "test2", and the user pauses between "test" and "2",
						// the db search can return AFTER the user has pressed 2
						// retriggering this function and catching the if part of this
						// if/else loop.
						// console.log("log.getStudyFromID sees", res )
						if(! res){ // study ID was not a valid study
							this.isShowStudyIDWarning = true
							this.studyPurpose = ''
							this.settingsForm.controls['studyID'].setErrors({"invalidStudyID": true})
						} else {
							this.isShowStudyIDWarning = false
							if(res.description){
								this.studyPurpose = res.description
							}
							this.settingsForm.controls['studyID'].setErrors(null)
							this.isStudyChanged = true
							this.newStudyID = res.studyID
						}
					})
				}
			}
		})


		// unclear what the newUserWatcher accomplishes in this context
		// const newUserWatcher = this.newUserSubect.pipe(
		// 	tap(uid => console.log( "change-study sees",uid )  ),
		// 	switchMap(uid =>
		// 		this.afStore.collection('participants', ref =>
		// 			ref.where("uid", "==", uid)))
		// ).valueChanges()

		// newUserWatcher.pipe(takeUntil(destroy$)).subscribe(res => {
		// 	console.log( "user doc found", res )
		// 	if(res.length){
		// 		destroy$.next(true)
		// 	}
		// })


		this.authObserver = afAuth.authState.subscribe( user => {
			console.log( "checking if user is logged in" )
			if(user) {
				// console.log( "logged in with uid", user.uid )
				this.afStore.firestore.doc("/participants/" + user.uid).get()
					.then(doc => {
						if(! doc.exists){
							// console.log( "doc not found for user, setting up watch." )
							this.newUserSubect.next(user.uid)
							return
						} else {
							let profile = doc.data()
							// console.log( profile )
							this.firstName = profile.firstName
							this.lastName = profile.lastName
							this.origStudyID = profile.studyID
							// let patchObj = {studyID: profile.studyID}
							// this.settingsForm.patchValue(patchObj)
						}
					}).catch(err => {
						console.warn("Error getting user info", err )
					})
			}}) // end auth guard

  }

	ngAfterViewInit() { }

	ionViewWillLeave(){
		this.authObserver.unsubscribe()
	}


	onSwipe(ev){
		// console.log(ev, ev.direction)
		if(ev.direction === 2){
			this.dismissModal("swipe")
		}
	}


	/**
	 * dismissModal
	 * @param answer
	 * Closes the modal, passing any text string back to the caller
	 * and also if the study was changed.
	 * */
  dismissModal(answer: string){
		if(this.isStudyChanged) {
			this.profile.changeStudyID(this.newStudyID)
		}
		this.modalCtrl.dismiss({
			isStudyChanged: this.isStudyChanged,
			response: answer
		})
	}

}



