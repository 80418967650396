import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import * as d3ScaleChromatic from 'd3-scale-chromatic';

import { MoodspacePoint } from "../interfaces/moodspacePoint";

@Injectable({
  providedIn: 'root'
})
export class SvgUtilsService{
	public COLORSPACEMAX = 100
	// svg colors taken from our scss definitions
	// tones
	public white = '#FFFFFF';
	public black = '#000000';
	public gray = '#939598';
	public grey = '#939598'; // for people who like to spell it that way.
	public lightGrey = d3.color(this.grey).brighter();

	// primary colors
	public pink = '#F471BA';
	public yellow = '#FBF288';
	public blue = '#74CEF3';
	public green = '#B9DC8E';
	public red = '#F88480';

	// secondary colors
	public brightGreen = '#4FC279'; // bright green
	public brightRed = '#FF0120'; // red

  constructor() {
    console.log('Hello SvgUtils Provider');
	}

	/**
	 * getGradientStopsArray
	 * @param point
	 * given a point (with params x,y) or a moodEntry, 
	 * return an array of gradient stops
	 * */
	getGradientStopsArray(point: MoodspacePoint){
		let x = 50
		let y = 50
		if(point.hasOwnProperty('affect')){
			x = point.affect
			y = point.activation
		} else if(point.hasOwnProperty('x')){
			x=point.x
			y=point.y
		} else {
			console.warn( "bad point to getGradientStopsArray, using defaults", point )
		}
		let brightstop = 3
		let darkstop = 1
		let color = this.getSpaceColor(x,y)
		let gstops = [
			{ offset:   '0%', stopColor: d3.rgb(color).brighter(brightstop) },
			{ offset:  '50%', stopColor: color },
			{ offset: '100%', stopColor: d3.rgb(color).darker(darkstop) }
		]
		return gstops
	}
	// for color interpolators see
	// https://github.com/d3/d3-scale-chromatic
	public getColor(h){
		var colorScale = d3.scaleLinear()
			.domain([0,100]) // tick steps
			.range([0.25,0.80])  // restrict range for nice colors
			.clamp(true);
		return d3ScaleChromatic.interpolatePuBu(colorScale(h)).toString();
		}

	public likertColor(h){
		const colorScale = d3.scaleQuantile()
			.domain([1, 2, 3, 4, 5]) // tick steps
			.range([0.07, 0.22, 0.55, 0.77, 0.93])  // restrict range for nice colors
		return d3ScaleChromatic.interpolateRdBu(colorScale(h)).toString()

		// const colorScale = d3.scaleLinear()
		// 	.domain([3,5]) // tick steps
		// 	.range([0.25,0.80])  // restrict range for nice colors
		// 	.clamp(true);
		// if(h < 3){
		// 	h = 6 - h
		// 	return d3ScaleChromatic.interpolatePuRd(colorScale(h)).toString();
		// } else {
		// 	return d3ScaleChromatic.interpolatePuBu(colorScale(h)).toString();
		// }
	}
	

	// progress color is used to track how close a given 
	// day (or a given log) is to the overall goal
	// it is defined on a scale of 0 to 100%,
	// i.e. the expected input is in [0,100]
	public getProgressColor(h){
		let y = 100 * Math.sqrt(2)/2
		let scale = d3.scaleLinear()
			.domain([0,100])
			.range([-y,y])
		let color = this.getMoodColorAsHex( 
			{affect: scale(h), activation:y} )
		return color
	}

	public getSecondaryColor(h){
		var colorScale = d3.scaleLinear()
			.domain([0,100]) // tick steps
			.range([0.25,0.80])  // restrict range for nice colors
			.clamp(true);
		return d3ScaleChromatic.interpolateBuPu(colorScale(h)).toString();
		}

	public getDangerColor(h){
		var colorScale = d3.scaleLinear()
			.domain([0,100]) // tick steps
			.range([0.25,0.80])  // restrict range for nice colors
			.clamp(true);
		return d3ScaleChromatic.interpolateOrRd(colorScale(h)).toString();
		}

	public getDivirgentColor(index, max?){
		if(!max) max = 5
		var colorScale = d3.scaleQuantize()
    .domain([0,max])
  .range([this.red, this.green, this.yellow, this.blue]);
		return(colorScale(index))


		// console.log(   d3ScaleChromatic.interpolateWarm(colorScale(index)).toString())
		// return d3ScaleChromatic.interpolatePlasma(colorScale(index)).toString()
		// return d3.scaleOrdinal(d3.schemeAccent)(index)
		// let foo = Math.floor(index*10)
		// return this.getColor(foo)
	}

	/**
	 * getMoodColorAsHex
	 * @param mood a moodspacePoint
	 * calls getMoodColor, then returns answer as a hex string
	 * sanity checks are all run elsewhere
	 * */
	public getMoodColorAsHex(mood: MoodspacePoint): string {
		let tmp = this.getMoodColor(mood)
		// console.log( tmp )
		return this.RGBColorToHex(tmp.toString())
	}
		
	/**
	 * getMoodColor
	 * @param mood a moodspacePoint
	 * this is a utility function which calls getSpaceColor
	 * after some sanity checks
	 * */
	public getMoodColor(mood: MoodspacePoint): string {
		let clampedAffect = mood.affect
		let clampedActivation = mood.activation
		if(mood.affect === undefined || mood.activation === undefined){
			console.warn( "Incomplete mood, cannot calculate color", mood )
			clampedAffect = 0
			clampedActivation = 0
		}
		if(Math.abs(mood.affect) > 100){
			if(!mood.id.startsWith("endpoint"))
				console.warn( "Mood affect out of range, clamping color", mood )
			clampedAffect = mood.affect/Math.abs(mood.affect) * 100
		}
		if(Math.abs(mood.activation) > 100){
			if(!mood.id.startsWith("endpoint"))
				console.warn( "Mood activation out of range, clamping color", mood )
			clampedActivation = mood.activation/Math.abs(mood.activation) * 100
		}
		return this.getSpaceColor(clampedAffect, clampedActivation)
	}

	/**
	 * getSpaceColor
	 * @param x
	 * @param y
	 * @return HSL value as a color hex code string
	 *
	 * Given values x,y representing a point in mood-space,
	 * return the color.
	 * neg-positive axis (x) runs from red to green,
	 * relax-excited axis (y) runs from blue to yellow.
	 * axis range is [-100, 100].
	 *
	 * Colors are based on a 4-basis color wheel derived from
	 * Hering's color opoponency hypothesis, with saturation
	 * of zero at the origin and saturation of 1 at the max.
	 *
	 * algo: 
	 * bounds checking
	 * identify quadrant
	 * find radial distance between quadrant endpoints
	 * interpolate between quad endpoints
	 * compute saturation using a thresholded bias function
	 * return HSL value
	 **/
	public getSpaceColor(x,y): string{
		const BOUNDS = [
			{h: 131, l: 0.36}, // 0 green
			{h:  59, l: 0.48}, // 1 yellow
			{h: 351, l: 0.29}, // 2 red
			{h: 228, l: 0.28}, // 3 blue
		]
		let GREY = d3.hsl(0,0.02,0.7) // a hint of red and very light
		// let altGrey = d3.hsl(233, 0.32, 0.85) // the zero point for the sliders, d deep blue
		 // http://hslpicker.com/#BBBFE2
		
		let quadS, quadE, alpha

		//  ---- bounds checking one -------
		if(Math.abs(x) > this.COLORSPACEMAX){
			// console.warn( "Bad color space value", x, y )
			x = x/Math.abs(x) * 100
		}
		if(Math.abs(y) > this.COLORSPACEMAX){
			// console.warn( "Bad color space value", x, y )
			y = y/Math.abs(y) * 100
		}
		//  ---- find quad bounds -------
		if(x >= 0 ){ 
			quadS = (y >= 0) ? 0 : 0 
			quadE = (y >= 0) ? 1 : 3 
		} else { 
			quadS = (y > 0) ? 2 : 2 
			quadE = (y > 0) ? 1 : 3
		}
		//  ---- bounds checking two -------
		//    x === 0 guard against
		//    point at origin or division by zero
		//    since alpha = atan(y/x)
		if(x === 0){ 
			if( y === 0) { // origin
				return GREY
			} else { //prevent division by zero
				alpha = 1
			}
		}
		//  ---- interpolate -------
		if(! alpha) { // bounds check sets alpha to one for x == 0
			alpha = Math.atan(Math.abs(y)/Math.abs(x)) * 2 / Math.PI
		}
		let wrap = (quadS === 2 && quadE === 1)
		let h = this.interpolate(alpha, 
			BOUNDS[quadS].h, BOUNDS[quadE].h, wrap)
		// find lightness between the two boundary colors
		let l = this.interpolate(alpha, BOUNDS[quadS].l, BOUNDS[quadE].l)
		// first get the saturation value
		let s = this.biasSaturation(Math.sqrt(x*x + y*y))
		// re-interpolate lightness between point and GREY's lightness
		l = this.interpolate(s, GREY.l, l)
		let col = d3.hsl(h,s,l)
		// console.log( quadS, quadE, alpha )
		// console.log( col )
		return col
	}

	/**
	 * interpolate
	 * @param val a number between 0 and 1
	 * @param start start value
	 * @param end end value
	 * @param wrap should we wrap around the degree circle?
	 * @returns lerp  the linear interpolated value between start and end
	 * */
	private interpolate(val: number, start: number, end: number, wrap?: boolean): number{
		if(Math.abs(val) > 1) {
			console.warn( "Bad value to linear interpolation, exiting with likely bad downstream effects", val )
			return start
		}
		// console.log( "interpolating from", start, "to", end )
		if(! wrap)
			return (1-val) * start + val * end
		// console.log( "wrapping around 360" )
		end = end + 360
		let interp = (1-val) * start + val * end
		if(interp > 360) interp -= 360
		return interp
	}

	/**
	 * biasSaturation
	 * @param val represents the radius of the circle
	 * @returns sat a color saturation value in [0,1]
	 * */
	private biasSaturation(val: number): number{
		if(val < 0 ){
			console.warn( "Bad saturation value, should be positive:", val )
			return 0
		}
		return Math.min(val, this.COLORSPACEMAX)/this.COLORSPACEMAX
	}

	/**
	 * safeColorToHex
	 * @param col
	 * convert any color object to hex
	 * where any includes
	 * hex, rgb strings, rgb objects, hsl strings, hsl objects
	 * */
	safeColorToHex(col: string): string{
		if(typeof col === 'string' && col.startsWith("#")) return col
		// console.log( col )
		// console.log( col.toString() )
		return this.RGBColorToHex(col)
	}

	/**
	 * RGBColToHex
	 * @param col a d3 rgb color object or its string representation
	 *   i.e.   Rgb {r: 255, g: 255, b: 255, opacity: 1}
	 *          rgb(255,255,255,1)
	 * @return a hex string matching the color
	 * */
	public RGBColorToHex(col): string {
		let color
		if(typeof col === "object") {
			color = col.toString()
		} else { color = col }
		let vals = color.match (/^rgb?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
		let ans = "#"  
		ans += ("0" + parseInt(vals[1],10).toString(16)).slice(-2) 
		ans += ("0" + parseInt(vals[2],10).toString(16)).slice(-2) 
		ans += ("0" + parseInt(vals[3],10).toString(16)).slice(-2) 
		return ans
		}

	public getQuantColor(h, ncolors){
		return this.green
	}

	/**
	 * pointToPercent
	 * @param mood Given a mood, convert its affect/activation to percentages
	 *    of their potential max value, given the circle bounds
	 * */
	 pointToPercent(mood){
		 let x = mood.affect
		 let y = mood.activation
		 let alpha = Math.atan2(y, x)
		 // since x and y are on a 0,100 scale and maxx/maxy are on a 0,1 scale,
		 // we can just divide as is to get the percentage figure
		 // EXCEPT-- when x or y is zero (since then the max value is screwy)
		 let percentY = y===0 ? 0 : y/Math.sin(alpha) 
		 let percentX = x===0 ? 0 : x/Math.cos(alpha)
		 let ans = {
			 percentAffect: Math.round(Math.abs(percentX)),
			 percentActivation: Math.round(Math.abs(percentY)),
		 }
		 return ans
	 }

	// returns a rounded rectangle
	public roundedRect(x: number, y: number, 
		length: number, height: number, 
		radius?: number){
		if(!radius) radius = Math.floor(height/2)
		// we fudge the bar length a little, since the end is curved
		// and the curve will not reach the control point
		let wx = Math.max(0, x + length - radius + 1)
		let wy = y + height
		let cx = wx + radius
		let path = "M" + x + " " + y + "H" + wx 
		path += "C" + cx + "," + y + " " + cx + "," + wy + " " + wx + "," + wy
		path += "H" + x + "Z"
		return path
	}

}
