import { Component, EventEmitter, HostListener, Input, Output, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, FormControl } from '@angular/forms';


@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent implements OnInit {

	private notesForm: FormGroup
	public notesValid: string = ''
	public defaultText: string = '';
	@Input() value: string;
	@Input() isSet: boolean;
	@Output() valueChange = new EventEmitter<string>();
	@Output() valueSelected = new EventEmitter<string>();

  constructor(
		formBuilder: FormBuilder,
	) {
    console.log('Hello TextInput Component');
		let notesTextControl = formBuilder.control("")
		this.notesForm = formBuilder.group({
			notesText: ''
		})
  }

	ngOnInit(){
		this.notesForm.patchValue({notesText: this.value})
	}

	// adjust size on window resize
	@HostListener('window:resize') windowResize(){
		if(document.getElementById("notesInput")){
		document.getElementById("notesInput").style.height =
			document.getElementById("notesInput").scrollHeight + "px"
		}
	}

	ngOnChanges(changes){
		// console.log( changes )
		if(changes.isSet && changes.isSet.currentValue){
			if(changes.value && changes.value.currentValue){
				// console.warn( this.value )
			this.notesForm.patchValue({notesText: changes.value.currentValue })
			}}
		// } else { this.notesForm.reset() }
	}


	// This is run whenever the user changes the input.
	@HostListener('input', ['$event.target'])
	onInput(element): void {
		// console.log( element )
		// console.log( "host listener", this.notesForm.value )
		// adjust the height of the element
		element.style.height = element.scrollHeight + "px";
		// console.log(this.notesForm.value)
		if(!this.notesForm.value){ return }
		if(!this.notesForm.value.notesText){ return }
		if(this.notesForm.value.notesText.length > 800){
			this.notesValid = 'warn'
		}
		if(this.notesForm.value.notesText.length > 1000){
			this.notesValid = 'bad'
		}
		this.valueChange.emit(this.notesForm.value.notesText)
	}

	saveNote(){
		// console.log( "called on blur" )
		this.valueSelected.emit(this.notesForm.value.notesText)
	}

}
