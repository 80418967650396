import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import { MoodspacePoint } from "../interfaces/moodspacePoint";


const labels = {
	"moodterm_1": {
		"qid": "moodterm_1",
		"term": {"en": "Satisfied, Pleased", "de": "zufrieden, froh"},
		"angle": 0,
		"order": 1
	},
	"moodterm_2": {
		"qid": "moodterm_2",
		"term": {"en": "Enthusiastic, Elated", "de": "enthusiastisch, begeistert"},
		"angle": 30,
		"order": 2
	},
	"moodterm_3": {
		"qid": "moodterm_3",
		"term": {"en": "Energetic, Excited", 
			"de": "energiegeladen, freudig erreg"},
		"angle": 60,
		"order": 3
	},
	"moodterm_4": {
		"qid": "moodterm_4",
		"term": {"en": "Aroused, Activated", "de": "aufgeweckt, aktiviert"},
		"angle": 90,
		"order": 4
	},
	"moodterm_5": {
		"qid": "moodterm_5",
		"term": {"en": "Frenzied, Jittery", "de": "hektisch, unruhig"},
		"angle": 120,
		"order": 5
	},
	"moodterm_6": {
		"qid": "moodterm_6",
		"term": {"en": "Upset, Distressed", "de": "verstimmt, betrübt"},
		"angle": 150,
		"order": 6
	},
	"moodterm_7": {
		"qid": "moodterm_7",
		"term": {"en": "Unhappy, Dissatisfied", "de": "unglücklich, unzufrieden"},
		"angle":180,
		"order": 7
	},
	"moodterm_8": {
		"qid": "moodterm_8",
		"term": {"en": "Sad, Gloomy", "de": "traurig, betrübt"},
		"angle": 210,
		"order": 8
	},
	"moodterm_9": {
		"qid": "moodterm_9",
		"term": {"en": "Sluggish, Tired", "de": "träge, müde"},
		"angle": 240,
		"order": 9
	},
	"moodterm_10": {
		"qid": "moodterm_10",
		"term": {"en": "Quiet, Still", "de": "still, ruhig"},
		"angle": 270,
		"order": 10
	},
	"moodterm_11": {
		"qid": "moodterm_11",
		"term": {"en": "Placid, Tranquil", "de": "gelassen, friedvoll"},
		"angle": 300,
		"order": 11
	},
	"moodterm_12": {
		"qid": "moodterm_12",
		"term": {"en": "Serene, Peaceful", "de": "seelenruhig, friedlich"},
		"angle": 330,
		"order": 12
	}
}

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

	moodspaceLabels 

	language: string = 'de'

	public minMoodsForNetwork = 30
		
  constructor() {
		console.log('Hello Constants Provider');
		this.moodspaceLabels = labels
	}

	/**
	 * getClosestMoodSpaceLabels
	 * @param mood a moodEntry 
	 * return a string with the strength of the emotion and 
	 * the brief description from the moodspaceLabels
	 * the moodStrengthScale is a simple helper
	 * */
	public moodStrengthScale = d3.scaleQuantize().domain([0,100])
		.range(["Leicht ", "Etwas ", "Sehr "])
		// .range(["Mildly ", "Somewhat ", "Strongly "])

	getClosesetMoodspaceLabels(mood: MoodspacePoint, language?: string): string{
		// if(! language) language='en'
		if(! language) language='de'
	// getClosesetMoodspaceLabels(mood: MoodEntry) {
		// get angle represented by mood
		let alpha = Math.atan2(mood.activation, mood.affect) * 180 / Math.PI
		if(alpha < 0) alpha += 360
		// find closest twelth 
		alpha = Math.round(alpha/30) + 1
		if(alpha > 12) alpha = 1
		let retval = ""
		if(this.moodspaceLabels && this.moodspaceLabels["moodterm_" + alpha]){
			retval = this.moodspaceLabels["moodterm_" + alpha].term[language].toLocaleLowerCase()
		}
		let r = Math.sqrt(mood.affect * mood.affect + mood.activation * mood.activation)
		if(retval) retval = this.moodStrengthScale(r) + retval
		if(r < 7) retval = this.language === 'de' ? "Leicht neutral" : "Mildly neutral"
		return retval
	}

getCircumplexAxis() {
	let de = {
		// energized: "energiegeladen",
		// energized: "angespannt",
		energized: "Erregt",
		relaxed: "Entspannen",
		negative: "Negativ",
		positive: "Positiv",
	}
	// let en = {
	// 	energized: "energized",
	// 	relaxed: "relaxed",
	// 	negative: "negative",
	// 	positive: "positive",
	// }
	return de
	}

	public objectToArray(obj): Array<any> {
		if(! obj){
			console.warn( "bad object sent to objectToArray", obj)
			return
		}
		let ans = []
		Object.keys(obj).forEach(key => ans.push(obj[key]))
		return ans.sort((a,b) => {
			if(a.order && b.order) return a.order - b.order
			if(a.name && b.name) return a.name > b.name ? 1 : -1
			return a[0] > b[0] ? 1 : -1
		})
	}


}
