import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators} from "@angular/forms/";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import 'firebase/firestore';
import { Subject } from "rxjs";
import { debounceTime, takeUntil, switchMap } from 'rxjs/operators';

import { LogService } from '../log.service';
import { ProfileService } from '../profile.service';


@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.page.html',
  styleUrls: ['./onboarding.page.scss'],
})
export class OnboardingPage implements OnInit {

	isAccountProvisioned: boolean = false // true when '/participants/{uid}' exists
	isPageComplete: boolean = false // true when name and studyID are set
	isOnboardingComplete: boolean = false // set by profileService when this page and notifications are set.

	isShowStudyIDWarning: boolean = false; // toggles warning messages
	studyPurpose: string = ''

	smsAllowed: boolean = false; //does the study allow SMS notifications?

	@Input() doneButtonText: string = "Fertig" // text displayed in the "done" button at bottom of page


	private authObserver
	private newUserSubect = new Subject<string>(); // used to watch for account creation for new users

	settingsForm: FormGroup;

  constructor(public modalCtrl: ModalController,
		public formBuilder: FormBuilder,
		afAuth: AngularFireAuth,
		public afStore: AngularFirestore,
		public profile: ProfileService,
		public log: LogService
	) {
		// console.log( "hello onboarding modal" )
		let destroy$: Subject<boolean> = new Subject<boolean>();
		// this.doneButtonText = "Fertig" // text displayed in the "done" button at bottom of page

		// TODO! if the user enters a name, then backspaces it,
		// the 'continue' button remains active
		this.settingsForm = this.formBuilder.group({
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
			studyID: ['', Validators.required],
			SMS: [''],
		})

		this.settingsForm.valueChanges.pipe(debounceTime(250)).subscribe(formRes => {
			// console.log( formRes )
			// If onboarding is not yet complete, test if it is now
			if(! this.isPageComplete){
				let tmp = this.settingsForm.valid &&
					this.isAccountProvisioned
				if(tmp) {
					this.isPageComplete = true
				}
			}
			let updateObj = { 
				firstName: formRes.firstName,
				lastName: formRes.lastName
			}
			if(this.isAccountProvisioned){
				this.profile.requestUpdateSettings(updateObj)
			}
			if(formRes.studyID !== ''){
				// console.log( "form sees:", formRes )
				this.log.setStudyFromID(formRes.studyID).then(logRes => {
					if(! logRes){
						this.isShowStudyIDWarning = true
						this.studyPurpose = ''
						this.settingsForm.controls['studyID'].setErrors({"invalidStudyID": true})
					} else { // study found
						this.isShowStudyIDWarning = false
						this.settingsForm.controls['studyID'].setErrors(null)
						if(logRes.description){
							this.studyPurpose = logRes.description
						}
						let updateObj = { studyID: logRes.studyID }
						if(logRes.isRandomTimes) {
							updateObj['isRandomTimes'] = true
						}
						this.profile.requestUpdateSettings(updateObj)
					}
				})
			}
		})


		const newUserWatcher = this.newUserSubect.pipe(
			switchMap(uid =>
				this.afStore.collection('participants', ref =>
					ref.where("uid", "==", uid)).valueChanges())
		)
		newUserWatcher.pipe(takeUntil(destroy$)).subscribe(res => {
			console.log( "user doc found", res )
			if(res.length){
				this.isAccountProvisioned = true
				destroy$.next(true)
			}
		})


		this.authObserver = afAuth.authState.subscribe( user => {
			// console.log( "checking if user is logged in" )
			if(user) {
				// console.log( "logged in with uid", user.uid )
				// var doc =  this.afStore.doc("/participants/" + user.uid)
				// this.afStore.doc("/participants/" + user.uid).snapshotChanges()
				// 	.subscribe(doc => {
				this.afStore.firestore.doc("/participants/" + user.uid).get()
					.then(doc => {
						if(! doc.exists){
							// console.log( "doc not found for user, setting up watch." )
							this.newUserSubect.next(user.uid)
							return
						} else {
							let profile = doc.data()
							// console.log( profile )
							this.isAccountProvisioned = true
							this.isOnboardingComplete = profile.isOnboardingComplete
							this.settingsForm.patchValue(profile)
						}
					}).catch(err => {
						console.warn("Error getting user info", err )
					})
			}}) // end auth guard

		// console.log( "end onboarding constructor" )
  }

	ngOnInit() {
		// console.log( "ng on init onboarding modal" )
	}

  ngAfterViewInit() { }

	ionViewWillLeave(){
		this.authObserver.unsubscribe()
	}


	onSwipe(ev){
		console.log("swipe event", ev, ev.direction)
		if(ev.direction === 2){
			this.dismissModal("swipe")
		}
	}


	/**
	 * dismissModal
	 * @param answer
	 * Closes the modal, passing any text string back to the caller
	 * */
  dismissModal(answer: string){
		console.log( "onboarding dismissed" )
		if(this.isPageComplete){ // hack nav guard
			this.modalCtrl.dismiss({response: answer })
		}
	}

}




