import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

import { ConstantsService } from "../constants.service";
import { LogService } from "../log.service";
import { SvgUtilsService } from "../svg-utils.service";

import { Answer } from "../../interfaces/answer";
import { MoodspacePoint } from "../../interfaces/moodspacePoint";
import { QuestionBlock } from "../../interfaces/questionBlock";


@Component({
  selector: 'app-question-block',
  templateUrl: './question-block.component.html',
  styleUrls: ['./question-block.component.css'],
})
export class QuestionBlockComponent implements OnInit {
	@Input () inBlock: QuestionBlock
	@Input() index: number

	public block: QuestionBlock
	public mode: string
	
	// common variables
	public currentColor: string = '';

	// circumplex variables
	public currentMood: MoodspacePoint = null;
	public currentWords: string = 'neutral'; // the words associated with the current location in mood space.
	public moodWordsBackground = "radial-gradient(#EFEFEF 30%, #B4B1B1)"
	@ViewChild('moodWords') moodWordsRef: ElementRef
	@ViewChild('inputContainer') inputContainer: ElementRef
	moodWordsEl

	answers: Object = {};

  constructor(
		public constants: ConstantsService,
		public log: LogService,
		public svgUtils: SvgUtilsService) {
		console.log('Hello QuestionBlock Component');
		log.currentEntrySubject.subscribe(entry => {
			this.bindEntryToBlock(entry)
		})
		}
	

	ngOnInit() {
		// console.log( "question block sees", this.inBlock )
	}

	// ngAfterViewInit(){
	// }

	// ngAfterViewChecked(){
	// 		console.warn( "qblock afterCHecked init width", this.inputContainer.nativeElement.offsetWidth)
	// }
	
	ngOnChanges(changes){
		// console.log( changes.inBlock )
		if(changes.inBlock && changes.inBlock.currentValue !== undefined){
			this.block = changes.inBlock.currentValue
			this.mode = this.block.scale
			if(this.mode === 'slider'){
				this.setSliders()
			}
			if(this.mode === 'likert'){
				this.setLikerts()
			}
		}
	}




	private bindEntryToBlock(entry, timeoutBlock?){
		// console.log( entry )
		if(!entry) return
		if(!this.block){
			// console.warn( "no block, setting timeout" )
			if(!timeoutBlock){
				setTimeout(() => {
					this.bindEntryToBlock(entry, true)
				}, 10)
				return
			}
		}
		// If log contains answers to any current block
		// items, update them
		let mood = {} as MoodspacePoint
		this.answers = {}
		entry.answers
			// .filter(item =>
			// item.blockID === this.block.blockID)
				.forEach(item => {
					// console.log( item )
					// switch (this.block.scale){
					switch (item.scale){
						case 'circumplex': {
							if(item.question === 'affect')
								mood.affect = item.answer
							if(item.question === 'activation')
								mood.activation = item.answer
							break;
						}
						case 'slider':{
							let tmp = Number.parseFloat(item.answer)
							if(Number.isNaN(tmp)){
								console.warn( "bad value for slider (may be text question)", item )
								tmp = 50
							}
							this.answers[item.qid] = item.answer
							break;
						}
						case 'likert':{
							// console.log( "likert", item.answer )
							let tmp = Number.parseFloat(item.answer)
							if(Number.isNaN(tmp)){
								console.warn( "bad value for slider (may be text question)", item )
								tmp = 3
							}
							this.answers[item.qid] = item.answer
							break;
						}
						case 'text':{
							// console.log( 'text block', item.answer )
							this.answers[item.qid] = item.answer
							break;
						}
						default: {
							console.warn( "unknown scale", this.block.scale )
						}
					}
				}) // end update block
		// update component state; also sets block as complete
		switch (this.block.scale){
			case 'circumplex': {
				this.updateCircumplex(mood) // updates display
				break
			}
			case 'slider':{
				this.setSliders()
				break
			}
			case 'likert':{
				this.setLikerts()
				break
			}
			case 'text':{
				this.setText()
			}
			default: {}
		}
	}

	/**************** Circumplex inputs ************************/

	/**
	 * updateCircumplex
	 * @param mood
	 * Update display variables according to this mood point
	 * */
	private updateCircumplex(mood: MoodspacePoint){
		// console.log( "updating circumplex", mood )
		this.block.isComplete = true
		// safety catch
		if(mood.affect === undefined || mood.activation === undefined){
			this.block.isComplete = false
			mood.affect = 0
			mood.activation = 0
		}
		// ok, go!
		this.currentMood = mood
		this.currentColor = this.svgUtils.getMoodColor(mood)
		this.currentWords = this.constants.getClosesetMoodspaceLabels(mood)
		this.moodWordsBackground = "radial-gradient(#EFEFEF 30%, " + this.currentColor + ")"
	}


	/**
	 * locationAt
	 * @param point a point in moodspace
	 *
	 * called when the input moodspace point is moving
	 * or when svg chart initializes...
	 * */
	locationAt(point){
		let mood = {affect: point.x, activation: point.y}
		this.currentWords = this.constants.getClosesetMoodspaceLabels(mood)
		this.currentColor = this.svgUtils.getMoodColorAsHex(mood)
		setTimeout(()=> {
			this.moodWordsBackground = "radial-gradient(#EFEFEF 30%, " + this.currentColor + ")"
		},10)
	}


	/**
	 * locationSelected
	 * @param point a point in moodspace
	 * called when a point is selected
	 * */
	locationSelected(point){
		let tmp1 = this.ansSkeleton()
		let tmp2 = this.ansSkeleton()
		this.log.recordAnswer([
			Object.assign(tmp1, {question: "affect", qid: "affect", answer: point.x}),
			Object.assign(tmp2, {question: "activation", qid: "activation", answer: point.y})
		])
		this.block.isComplete = true
	}

	/**************** Slider/Likert inputs ************************/
	setSliders(){
		let isComplete = true
		this.block.questions.forEach(item => {
			item.labelLeft = this.block.anchors[0]
			item.labelRight = this.block.anchors[this.block.anchors.length - 1 ]
			if(this.answers[item.qid] !== undefined ){
				item.value = this.answers[item.qid]
				item.isSet = true
			} else {
				item.value = 50
				item.isSet = false
				isComplete = false
			}
		})
		this.block.isComplete = isComplete
	}

	setLikerts(){
		let isComplete = true
		this.block.questions.forEach(item => {
			item.maxval = this.block.anchors.length
			let halfway = (item.maxval - 1)/2
			// console.log( item.maxval, halfway + 1 )
			item.labels = this.block.anchors
			if(this.answers[item.qid] !== undefined ){
				item.value = this.answers[item.qid]
				item.isSet = true
			} else {
				item.value = halfway + 1
				item.isSet = false
				isComplete = false
			}
		})
		this.block.isComplete = isComplete
	}

	/**
	 * markValueChanged
	 * @param val the value of the slider
	 * @param qu the question which the slider controls
	 *
	 * called when slider moves. qu.isChanged is used as
	 * a toggle in the HTLM to turn on/off display of the
	 * slider value
	 **/
	markValueChanged(val, qu){
		qu.isChanged = true
	}

	/**
	 * updateSliderState
	 * @param val the value of the slider
	 * @param question the question which the slider controls
	 *
	 * called when slider control is released after a change
	 * IMPORTANT! Tied to both sliders and likerts
	 * */
	updateSliderState(val, question){
		// console.log( "Slider/Likert ", question.qid, " set to ", val )
		this.answers[question.qid] = val
		let tmp = this.ansSkeleton()
		tmp = Object.assign(tmp, {
			question: question.text,
			qid: question.qid,
			answer: val
		})
		this.log.recordAnswer(tmp)
	}


	setText(){
		// console.log( "setting text inputs" )
		let isComplete = true
		this.block.questions.forEach(item => {
			if(this.answers[item.qid] !== undefined ){
				item.value = this.answers[item.qid]
				item.isSet = true
			} else {
				item.value = ''
				item.isSet = false
				isComplete = false
			}
		})
		this.block.isComplete = isComplete
	}


	/**
	 * updateTextState
	 * @param val the value of the text entry
	 * @param quesion the question associated with the text entry
	 *
	 * called when text entry blurs
	 * */
	updateTextState(val, question){
		// console.log( 'recording text', val, question )
		this.answers[question.qid] = val
		let tmp = this.ansSkeleton()
		tmp = Object.assign(tmp, {
			question: question.text,
			qid: question.qid,
			answer: val
		})
		// console.log( tmp )
		this.log.recordAnswer(tmp)
	}


	/**************** Generic inputs ************************/
	/**
	 * ansSkeleton
	 * creates an answer skeleton from the current block.
	 * Core to all functions which convert an input to
	 * an answer.
	 * */
	private ansSkeleton(): Answer {
		let tmp = Object.assign({}, this.block)
		let spuriousKeys = ["questions", "instructions",
			"anchors", "hasAnchors"]
		spuriousKeys.forEach(key => delete tmp[key] )
		return tmp as Answer
	}

}
