import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SurveyPageRoutingModule } from './survey-routing.module';

import { SurveyPage } from './survey.page';

import { QuestionBlockComponent } from '../question-block/question-block.component';
import { SliderComponent } from "../slider/slider.component";
import { LikertComponent } from "../likert/likert.component";
import { SvgChartComponent } from "../svg-chart/svg-chart.component";
import { TextInputComponent } from "../text-input/text-input.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    SurveyPageRoutingModule
  ],
  declarations: [SurveyPage, QuestionBlockComponent, SliderComponent, LikertComponent, SvgChartComponent, TextInputComponent]
})
export class SurveyPageModule {}
