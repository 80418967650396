import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { LogService } from "../log.service";
import { ProfileService } from "../profile.service";
import { UtilitiesService } from "../utilities.service";

import { Study } from "../../interfaces/study";

import { ChangeStudyPage } from '../change-study/change-study.page';
import { NotificationsPage } from '../notifications/notifications.page';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
})
export class SettingsPage implements OnInit {
	ngOnInit() { }
	// need to initialize this for display purposes
	study: Study = {ownerUniversity: {name: ''} } as Study;
	ownerName = ''
	isDataLoaded: boolean = false

	isScheduleSet: boolean = false
	isRandomTimes: boolean = false
	notificationSchedule: number[] = []

	isChangeStudyAllowed: boolean = false
	isChangeScheduleAllowed: boolean = false
	highlightChangeSchedule: boolean = false

	constructor(
		public modalCtrl: ModalController,
		logService: LogService, 
		profile: ProfileService,
		utils: UtilitiesService
	) {
		// console.warn("changestudy set to true, delete")
		this.isChangeStudyAllowed = false // delete this line
		// console.warn("change schedule set to true, delete")
		this.isChangeScheduleAllowed = true // delete this line
		logService.currentStudySubject.subscribe(res => {
			if(res){
				this.study = res
				if(res.isRandomTimes){
					this.isRandomTimes = true
				}
				if(res.ownerDisplayName){
					this.ownerName = res.ownerDisplayName
				}
				this.isDataLoaded = true
			}
		})
		profile.userSettings.subscribe(res => {
			if(!res) return
			// console.log( "settings sees new profile", res )
			if(res.notificationSchedule){
				this.isScheduleSet = true
				this.notificationSchedule = res.notificationSchedule
					.map( time => utils.serverToLocalTime(time) )
					.sort((a, b) => a - b)
			} else {
				this.highlightChangeSchedule = true
			}
			if(res.isFinished){
				this.isChangeStudyAllowed = true
				this.isChangeScheduleAllowed = true
			}
			if(res.priorStudies && res.priorStudies.length > 0){
				this.isChangeScheduleAllowed = true
			}
		})
  }


  ngAfterViewInit() { }

	/**
	 * openChangeStudyModal
	 * Opens the notifications modal
	 * Useful if i.e. notification settings need to change
	 * */
	async openChangeStudyModal(){
		const modal = await this.modalCtrl.create({
			component: ChangeStudyPage,
			componentProps: {
				source: 'settingsPage'
			}
		})
		modal.onDidDismiss().then( (data) => {
			console.log( "study change:",data )
			// if(data.isStudyChanged){
			// 	this.isChangeScheduleAllowed = true
			// 	this.highlightChangeSchedule = true
			// 	// this.openNotificationModal()
			// }
		}).then(() => this.openNotificationModal())
		return await modal.present()
	}


	/**
	 * openNotificationModal
	 * Opens the notifications modal
	 * Useful if i.e. notification settings need to change
	 * */
	async openNotificationModal(){
		const modal = await this.modalCtrl.create({
			component: NotificationsPage,
			componentProps: {
				source: 'settingsPage'
			}
		})
		modal.onDidDismiss().then( (data) => {
			// console.log("notifications modal dismissed",data )
			this.highlightChangeSchedule = false
		})
		return await modal.present()
	}

}
