import { Component, ElementRef, HostListener, Input, ViewChild, OnInit } from '@angular/core';

// import * as d3 from 'd3';
import { select } from 'd3-selection';
import { SvgUtilsService } from "../svg-utils.service";

// Since the bars are set in the template, and the bars need to be in a different 
// order depending on how we display the bar extent, either:
//    learn how to non-destructively change the order/zlevel of svg elements
//    or
//    split this into two component types
@Component({
  selector: 'app-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.scss'],
})
export class GaugeComponent implements OnInit {
	ngOnInit() {}

	@Input() value: number
	@Input() maxValue: number;
	@ViewChild('imagePane') imagePane: ElementRef;

	unitsSign: string = "%";  // i.e. blank, or '%', or ...
	private isInitialized: boolean = false;
	private _maxValue: number = 100;
	public displayVal: number = 0; // the number displayed as text by the gauge
	public width: number
	public height: number
	public gaugeWidth: number 
	public attributes: Object = {
		borderStrokeWidth: 2,
		borderStroke: 'none',
		borderFill: this.svgUtils.lightGrey,
		// borderStroke: this.svgUtils.grey,
		// borderFill: 'none',
	}

	// private glitterX = 10;
	// private glitterTimer

  constructor( public svgUtils: SvgUtilsService ) {
    // console.log('Hello HcSvgGauge Component');
  }

	@HostListener('window:resize') windowResize(){
		if(this.value || this.value === 0) {
			let validInput = this.validateInputs(this.value, this._maxValue)
			this.renderGauge(validInput)
		}
	}

	ngAfterViewInit(){
		// console.log( "view init" )
		this.isInitialized = true
		// console.log( this.value, this.maxValue )
		if(typeof(this.maxValue) === 'number'){
			this._maxValue=this.maxValue
		}
		let validInput =  this.validateInputs(this.value, this._maxValue)
		// If we render the gauge directly, it triggers a 
		// Expression has changed after it was checked
		// error. Delaying the rendering by one tick is the fix
		setTimeout(()=>{
			this.renderGauge(validInput)
		},10)
	}


	ngOnChanges(changes){
		// console.log( "changes" )
		// console.log( changes )
		// first change is called before view is initialized, thus
		// we do not want to render here on creation
		if(! this.isInitialized) {
			this.isInitialized = true
			return  // return here as view is not yet initialized
		}
		if(changes.value){
			console.log( "value", changes.value.currentValue)
		}
		if(changes.maxValue){
			this._maxValue = changes.maxValue.currentValue
		}
		let validInput =  this.validateInputs(changes.value.currentValue, this._maxValue)
		this.renderGauge(validInput)
	}

	/**
	 * validateInputs
	 * @param val the gauge value
	 * @param maxVal the maximum value
	 * @returns normalized val or null
	 * Normalize val as a percent of maxVal; i.e. a number between 0 and 100.
	 * */
	private validateInputs(val: number, maxVal: number): number{	
		// console.log( val, maxVal )
		if(typeof val !== "number"){
			console.warn("Non-numeric value sent to gauge: ")
			console.warn(val)
			return null
		}
		if(val < 0) { 
			console.warn("Negative value sent to gauge: ", val)
			return null
		}
		if(maxVal < 0) { 
			console.warn("Negative max value sent to gauge: ", maxVal)
			return null
		}
		if(val > maxVal) val = maxVal
		if(maxVal === 100){
			if(val < 1 ) { val *= 100 }
		} else {
			val = val * 100 / maxVal
		}
		return val
	}

	private renderGauge(val): number{
		if(! this.isInitialized){ return }
		// console.warn( "rendering gauge with val ", val )
		if(val === null) return
		this.displayVal = Math.round(val)
		
		const margin={'bottom': 15, 'sides': 2}
		this.width = this.imagePane.nativeElement.offsetWidth
		this.height = this.width/2;
		this.gaugeWidth = this.width * 0.15;
		this.attributes['borderStrokeWidth'] = this.gaugeWidth * 0.1

		const fontSize = 12 * this.width/100;

		const svg = select(this.imagePane.nativeElement).select('svg')
			.attr('width', this.width).attr('height',this.height + margin.bottom)
		// .transition(trans)
		svg.select("#borderPath")
			.attr("d", this.generatePath())
		svg.select("#valueText")
			.attr("x", this.width/2)
			.attr("y", this.height + margin.bottom/2)
			.attr('font-size', fontSize )
		// .transition(trans)
		svg.select("#fillPath")
			.transition().duration(() => val * 5)
			.attr("fill", this.svgUtils.getColor(val))
			.attrTween("d", () => {
				return (t) => {
					return this.generatePath(val * t)
				}
			})
	}				 

	/**
	 * generatePath
	 * @param val <optional> the value for the gauge, on a scale of 0 to 100
	 * This function generates both the fill and the border arcs. If VAL is 
	 * specified, it generates the fill. If not, it generates the border
	 * Fill has rounded ends, border/inner bar does not
	 * REFACTOR: IF border fill and !border stroke, 
	 *     replace the border with an inner bar. 
	 * */
	private generatePath(val?){
		if(val === 0){val = 0.1} // sanity check
		let pad = 2*this.attributes['borderStrokeWidth'];
		pad = Math.round(pad)
		let radius = this.width/2 - pad
		let barWidth = this.gaugeWidth

		// set if gauge extent is shown by an inner bar or an border/outline
		if(!val){  // only applies if we are drawing the border
			if(this.attributes['borderStroke'] === 'none'){
				// gauge range shown by inner bar
				barWidth = this.gaugeWidth * 0.8
				pad += this.gaugeWidth * 0.1
				radius = this.width/2 - pad
			} 
		}

		let extent = val? 1.8 * val : 180 // angle of arc extent, from 0 to 180
		let theta = (180 - extent) * Math.PI / 180 // convert degrees to radians
		let arcX = Math.cos(theta)
		let arcY = Math.sin(theta)
		// Path coordinates are complex, since the trig gives us 
		// position from the origin of the circle, while SVG coords 
		// have their origin in the upper left.
		let Xcorrection = this.width/2

		let path = `M ${pad},${this.height} `
		path += `A ${radius},${radius} 0 0,1 ${Xcorrection + arcX * radius},${this.height - arcY * radius} `
		radius -= barWidth
		if(val){ // if(val && val < 99){
			path += `A ${barWidth * 0.5},${barWidth * 0.5 * 0.25} ${extent - 180} 1,1  ${Xcorrection + arcX * radius},${this.height - arcY * radius} `
		} else {
			path += `L ${Xcorrection + arcX * radius},${this.height - arcY * radius} `
		}
		path += `A ${radius},${radius} 0 0,0 ${barWidth + pad },${this.height}`
		if(val) { // close the path with an arc
			path += `A ${barWidth * 0.5},${barWidth * 0.5 * 0.25} 0 1,1  ${pad},${this.height} `
		} else { path += "Z" }
		return path
	}
	
}

