import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import { LogService } from "../log.service";
import { ExportService } from '../export.service';

import { Study } from "../../interfaces/study";

@Component({
  selector: 'app-terms',
  templateUrl: './terms.page.html',
  styleUrls: ['./terms.page.scss'],
})
export class TermsPage implements OnInit {
	ngOnInit() { }

	isAuth: boolean = false;
	// study: Study;
	study: Study = {
		ownerDisplayName: "den Ansprechpartner der Studie",
		ownerEmail: undefined
	} as Study;

  constructor(
		auth: AngularFireAuth,
		logService: LogService, 
		public exportService: ExportService
	) {
		auth.authState.subscribe(user => {
			if(user) { this.isAuth = true }
			else { this.isAuth = false }
		})
		logService.currentStudySubject.subscribe(res => {
			if(res){
				// console.log( res )
				this.study = res
				// commented out info is not used
				// if(res.ownerDisplayName){
				// 	this.ownerName = res.ownerDisplayName
				// }
				// this.isDataLoaded = true
			}
		})
  }


  ngAfterViewInit() { }

	exportData(){
		this.exportService.exportUserData()
	}

}
