// setup instructions
// https://github.com/luwojtaszek/ngx-excel-export

import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
// import 'firebase/firestore';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { AlertController } from "@ionic/angular";

import { take } from 'rxjs/operators';

import { LogService } from "./log.service";
import { ProfileService } from "./profile.service";
import { Participant } from "../interfaces/participant";


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const SPURIOUSKEYS = [
	"usk", "uid", "id", 'secretKey',
	'studyFBkey', 'studyFBKey',
	"autosaveOnHide", "autosaveOnTimer",
	"isFailing", 'isSuccessful', 'isComplete',
	"isResearcher","isFinished",
	'isShowMocks', 'isOnboardingComplete',
	"networkDocID"
]


@Injectable({
  providedIn: 'root'
})
export class ExportService {
	// set the headers for the answers export.
	// We extend this to include the answers to the survey questions the
	// first time we parse a log entry.
	private answersHeader = ["studyID", "date", "time"]
	private isStudyInHeader: boolean = false // used to trigger side effect

	isAuth: boolean = false

  constructor(
		auth: AngularFireAuth,
		public afFirestore: AngularFirestore,
		public log: LogService,
		public profile: ProfileService,
		public alertCtrl: AlertController
	) {
		console.log('Hello ExportService Service');
		auth.authState.subscribe(user => {
			if(user) { this.isAuth = true }
			else { this.isAuth = false }
		})
  }


	/** 
	 * exportUserData
	 * Download user data, clean it, and call the export function.
	 * */
	public exportUserData(){
		if(!this.isAuth) return
		// combineLatest(this.log.allEntriesSubject, 
		// this.profile.userSettings).take(1).subscribe(([log, profile]) => {
		this.profile.userSettings.pipe(take(1)).subscribe((profile: Participant) => {
			this.afFirestore.firestore.collection('logEntries')
				.where('usk', '==', profile.secretKey)
				.orderBy("timestamp", "desc") // most recent first
				.get().then( logSnapshot => {
					let log = []
					logSnapshot.forEach( entry => {
						log.push(entry.data())
					})
					if(log.length === 0 || profile === null){
						this.createAccessAlert()
						return
					}
					let cleanLog = this.cleanLog(log)
					let cleanProfile = this.cleanProfile(profile)
					let fileName = `${profile.firstName} ${profile.lastName} Journal`
					this.exportAsXLS(cleanLog, cleanProfile, fileName)
				})
		})
	}

	async createAccessAlert(){
		const alert = await this.alertCtrl.create(
			{header: "Problem accessing data",
				buttons: ['Dismiss']})

		await alert.present()
	}

	/**
	 * exportAsExcelFile
	 * @param log the user's log
	 * @param profile the user's profile
	 * @param excelFileName the file name
	 * convert everything to excel format and export
	 * WARNING!! Assumes data is cleaned prior to export!!
	 * */
	private exportAsXLS(log: any[], profile: any[], excelFileName: string): void {
		const ws_log: XLSX.WorkSheet = XLSX.utils.json_to_sheet(log, {header: this.answersHeader});
		const ws_profile: XLSX.WorkSheet = XLSX.utils.json_to_sheet(profile);
		let workbook = XLSX.utils.book_new()
		XLSX.utils.book_append_sheet(workbook, ws_log, 'Journal')
		XLSX.utils.book_append_sheet(workbook, ws_profile, 'Profile')
		const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
		const data: Blob = new Blob([excelBuffer], {
			type: EXCEL_TYPE
		});
		FileSaver.saveAs(data, excelFileName + EXCEL_EXTENSION);
		// FileSaver.saveAs(data, excelFileName + new Date().getTime() + EXCEL_EXTENSION);
	}


	private cleanProfile(profile: Participant): any[]{
		// make a clean copy
		let clean = Object.keys(profile)
			.filter( key => SPURIOUSKEYS.indexOf(key) === -1)
			.reduce((newObj, key) =>
				Object.assign(newObj, { [key]: profile[key] }), {})
		return [ clean ]
	}


	private cleanLog(json: any[]): any[] {
		console.log( json )
		// make a clean copy
		let clean = json.map(item => Object.keys(item)
			.filter( key => SPURIOUSKEYS.indexOf(key) === -1)
			.reduce((newObj, key) =>
				Object.assign(newObj, { [key]: item[key] }), {})
		)
		// add user answers 
		// convert the timestamp to time/date
		// the "answersToJson" function cleans the answers
		this.isStudyInHeader = false // set up side effect trigger
		clean.forEach(item => {
			if(item.hasOwnProperty('timestamp')){
				let ts = new Date(item['timestamp'])
				item['date'] = ts.toLocaleDateString()
				item['time'] = ts.toTimeString()
				delete item['timestamp']
			}
			if(item.hasOwnProperty("answers")) {
				item = Object.assign(item, this.answersToJson(item['answers']))
				delete item['answers']
			} })
		console.log( clean )
		return clean
	}


	/**
	 * answersToJson
	 * @param answers an array of answers from a log entry item
	 * @return obj an json object whose keys represent the answers 
	 * Sort the answers by block and by question, and build the json object
	 * WARNING! SIDE EFFECT
	 *   The first time this is called, it is used to set the headers for
	 *   the answers export.
	 * */
	answersToJson(ans: any[]): object {
		let res = {}
		ans.sort((a,b) => a.blockIndex - b.blockIndex).forEach(item => {
			let question = this.camelCaseConverter(item.blockName + '__ ' + item.question)
			res[question] = Math.round(item.answer)
			if(isNaN(res[question])){
				res[question] = item.answer
			}
			if(!this.isStudyInHeader){ // side effect here
				this.answersHeader.push(question)
			}
		})
		if(!this.isStudyInHeader){ // toggle side effect
			this.isStudyInHeader = true
		}
		return res
	}


	/**
	 * camelCaseConverter
	 * @param txt a string to convert
	 * converts string, also removes special characters
	 * */
	camelCaseConverter(txt: string): string {
		return txt.replace(/[^\w\s]/gi, '')
			.replace(/\W+(.)/g, function(match, chr)
       {
            return chr.toUpperCase();
        });
	}


}
