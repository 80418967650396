import { Injectable } from '@angular/core';
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import 'firebase/firestore';
import { BehaviorSubject } from "rxjs";
import { Subject } from "rxjs";
import { pipe } from 'rxjs';
import { take, tap, switchMap } from 'rxjs/operators';

import { Participant } from "../interfaces/participant"
import { UtilitiesService } from "./utilities.service";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
	private uidSubject
	private isKnownUser: boolean = false
	private uid: string = ''
	private userEmail: string = ''

	userSettings: BehaviorSubject<Participant>;
	studyChanged: Subject<boolean>;

	private notificationTimes: number[] = []; // in SERVER time

	constructor(
		public afAuth: AngularFireAuth,
		public afStore: AngularFirestore,
		public utils: UtilitiesService
	) {
		console.log('Hello Profile Provider');
		this.userSettings = new BehaviorSubject(null)
		this.studyChanged = new Subject()
		this.uidSubject = new Subject()

		// this.uidSubject.switchMap(uid =>
		// 	this.afStore.doc('participants/' + uid).valueChanges()
		this.uidSubject.pipe(
			// tap(uid => console.log("uid change",uid)),
			switchMap(uid => 
				this.afStore.doc('participants/' + uid).valueChanges()
			)
		).subscribe(userProfile => {
			// console.log( "User Profile", userProfile )
			this.notificationTimes = []
			if(userProfile === null){ // no doc found for user, create one
				this.createUserSkeleton()
				return
			}
			if(! userProfile) return
			if(! userProfile.isOnboardingComplete){
				let ans = this.checkIsOnboardingComplete(userProfile)
				if(ans) {
					this.requestUpdateSettings( {
						isOnboardingComplete: true,
						numNotifications: 1,
					})
				}
			}
			if(Array.isArray(userProfile.notificationSchedule)){
				this.notificationTimes = userProfile.notificationSchedule
			}
			if(userProfile.isRandomTimes && Array.isArray(userProfile.randomNotificationSchedule)){
				this.notificationTimes = userProfile.randomNotificationSchedule
			}
			// console.warn( "in setter", this.notificationTimes )
			this.userSettings.next(userProfile)
		})

		afAuth.authState.subscribe(user => {
			if(user) {
				this.isKnownUser = true
				this.uid = user.uid
				this.userEmail = user.email
				this.uidSubject.next(user.uid)
				this.afStore.doc('researchers/' + user.uid)
					.valueChanges().pipe(take(1))
					.subscribe(res => {
						if(res) {
							this.updateSettings({isResearcher: true})
						}
					})
			}
		})
	}

	/**
	 * createUserSkeleton
	 * allows the creation of a participant object in firebase
	 * useful if a deleted participant logs back in.
	 * */
	private createUserSkeleton(): Promise<any>{
		console.log( "creating skeleton" )
		// create the USK
		const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789=-';
		let key = '';
		for (let i = 0; i < 20; i++) {
			let randomPos = Math.floor(Math.random() * charSet.length);
			key += charSet.substring(randomPos, randomPos+1);
		}
		// create the rest of the object
		let skeleton = { uid: this.uid, email: this.userEmail,
			secretKey: key, isResearcher: false }
		// use new firebase way
		return this.afStore.collection('participants')
		 .doc(this.uid).update(skeleton)
		// formerly
		// return firebase.firestore().collection('participants')
		// .doc(this.uid).set(skeleton, {merge: true})
	}


	/**
	 * requestUpdateSettings
	 * @param obj
	 * the public interface to update a settings object
	 * generic. Cleans the entry and passes it to
	 * the internal method.
	 *
	 * Could add more safety checks:
	 * 		delete any key that is not part of the
	 * 			profile interface.
	 * */
	public requestUpdateSettings(obj): Promise<any>{
		// console.log( "updating user profile", obj )
		if(this.isKnownUser){
			return this.updateSettings(obj)
		}
		return Promise.reject({message:"profile service does not yet have a user loaded"})
	}


	/**
	 * changeStudyID
	 * @param studyID a studyID
	 * If the study ID is valid,
	 * adds the current study ID to the previous studies list
	 * sets the current study to the given study ID
	 * update user vars around study status
	 *
	 * CAUTION!! Assumes study ID refers to a valid study!
	 *
	 **/
	public changeStudyID(studyID: string) {
		this.userSettings.pipe(take(1)).subscribe( currrentSettings => {
			if (currrentSettings.studyID == studyID){
				return false
			}
			let updateObj = {
				studyID: studyID,
				priorStudies: [], // next step fills this in
				responseRate: 100,
				duration: 1,
				isOnboardingComplete: false,
				isSuccessful: true,
				isFinished: false,
				notificationSchedule: null,
				isRandomTimes: null,
				randomNotificationSchedule: null
				// TODO! replace null with a real delete
				// notificationSchedule: this.afStore.firestore.FieldValue.delete(),
				// isRandomTimes: this.afStore.firestore.FieldValue.delete(),
				// randomNotificationSchedule: this.afStore.firestore.FieldValue.delete()
			}
			if(currrentSettings.priorStudies) {
				updateObj.priorStudies = [...currrentSettings.priorStudies]
			}
			updateObj.priorStudies.push( currrentSettings.studyID )
			this.updateSettings(updateObj).then(res => {
				this.studyChanged.next(true)
			}).catch(err => {
				console.log( "failed to update study" , err) // TODO! What is this error? The DB seems to update even when an error is signaled ?? WTFFB
				// console.log(updateObj)
			})
		})
	}


	/**
	 * updateSettings
	 * @param obj
	 * performs the actual update. 
	 * Returns the response from Firebase
	 * */
	private updateSettings(obj): Promise<any>{
		// sanitize the update object
		//    remove blacklisted keys,
		//    undefined keys, and
		//    empty strings (except phoneNumber)!!
		let blacklist = ['secretKey','uid']
		Object.keys(obj).forEach(key => {
			if(blacklist.indexOf(key) > -1){
				console.warn( "attempt to update blacklisted key", key )
				delete obj[key]
			}
			if(obj[key] === undefined){
				delete obj[key]
			}
			if(obj[key] === ''){
				// if(key != 'phoneNumber'){
				// 	delete obj[key]
				// }
			}
		})
		// if anything left, update Firebase
		// else return true
		if(Object.keys(obj).length > 0) {
			return this.afStore.doc('/participants/' + this.uid).update(obj)
		} else {
			return Promise.resolve(true)
		}
	}


	/**
	 * checkIsOnboardingComplete
	 * @param profile a user profile
	 * Checks if onboarding is finished
	 * */
	public checkIsOnboardingComplete(profile: Participant): boolean{
		let obItems = ['firstName', 'lastName', 'studyID',
			'notificationSchedule', ]
		let missing = obItems.filter(item => {
			if (Object.keys(profile).indexOf(item) === -1 ) return true
			return ! Boolean(profile[item])
		})
		if(missing.length) {
			// console.log( "Onboarding still needs", missing )
		} else {
			// console.log( "Onboarding complete" )
		}
		return missing.length === 0
	}


	/**
	 * signOut
	 * Signs the user out from the app, and unsubscribes
	 * from all subscriptions
	 * */
  public signOut() {
		console.log( "Signing out." )
		// unsubscribe from all subscriptions
		// then log out of the app
    this.afAuth.signOut();
  }


	/**
	 * getLocalNotificationTimes
	 * @returns notification schedule in local time
	 * */
	public getLocalNotificationTimes(){
		let tmp = []
		this.notificationTimes.forEach(x => {
			tmp.push(this.utils.serverToLocalTime(x))
		})
		// console.warn( "in function", tmp)
		return tmp
	}

}



