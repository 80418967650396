/**
 * userActivityService
 *
 * Monitors if the page is backgrounded, 
 * also uses times to trigger events 
 * i.e. inactive user
 *
 * Page visibility change, see:
 * https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
 * */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { Subject } from "rxjs";
import { interval, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UserActivityService {
	// subjects, used to trigger/restart the different timers
	private autosaveRestart
	private moodTimeoutRestart

	/**
	 * public interface
	 * */
	// time constants
	// public moodTimeoutPeriod:number = 30000
	public autosavePeriod: number = 0.5 * 60*1000 // half minute
	public moodTimeoutPeriod: number = 10 * 60*1000 // 10 minutes
	public entryBlockPeriod: number = 60 * 60*1000// 60 minutes

	// Subjects emit on events
	public isHiddenSubject: BehaviorSubject<boolean>;
	public autosaveSubject: Subject<number>;
	public moodTimeoutSubject: Subject<number>;
	// public isEntryBlockedSubject: BehaviorSubject<boolean>;


  constructor() {
    console.log('Hello UserActivityService Service');
		// default state: window is not hidden
		this.isHiddenSubject = new BehaviorSubject(false)
		this.autosaveRestart = new Subject();
		this.moodTimeoutRestart = new Subject();
		this.autosaveSubject = new Subject();
		this.moodTimeoutSubject = new Subject();
		// this.isEntryBlockedSubject = new BehaviorSubject(false)

		/**
		 * set up autosave trigger and moodTimeout interval
		 * */
		this.autosaveRestart.pipe(
			switchMap(trigger => {
			return timer(this.autosavePeriod)
		})).subscribe(this.autosaveSubject)

		this.moodTimeoutRestart.pipe(
			switchMap(trigger => {
			return interval(this.moodTimeoutPeriod)
		})).subscribe(this.moodTimeoutSubject)
		this.moodTimeoutRestart.next(1)

		/**
		 * set up visibility watcher
		 * */
		// account for different browser implementations
		let hidden, visibilityChange; 
		if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support 
			hidden = "hidden";
			visibilityChange = "visibilitychange";
		} else if (typeof document['msHidden'] !== "undefined") {
			hidden = "msHidden";
			visibilityChange = "msvisibilitychange";
		} else if (typeof document['webkitHidden'] !== "undefined") {
			hidden = "webkitHidden";
			visibilityChange = "webkitvisibilitychange";
		}
		// add the event listener
		document.addEventListener(visibilityChange, 
			(ev) => {
				// console.log( "visibility change:", document.visibilityState )
				// console.log( ev )
				this.isHiddenSubject.next(document[hidden])
			}, // end event callback function
			false) // end event listener
	}

	resetMoodTimeout(){
		// console.log( "resetting mood timeout" )
		this.moodTimeoutRestart.next(this.moodTimeoutPeriod)
	}

	resetAutosave(){
		// console.log( "resetting autosave" )
		this.autosaveRestart.next(this.autosavePeriod)
	}

	setMoodTimeout(minutes: number){
		// console.warn( "resetting mood timeout period" , minutes)
		this.moodTimeoutPeriod = minutes * 60*1000
	}

}
