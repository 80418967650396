import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators} from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { AlertController, ModalController } from '@ionic/angular';
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';

import { OnboardingPage } from '../onboarding/onboarding.page';
import { NotificationsPage } from '../notifications/notifications.page';

import { ProfileService } from '../profile.service';
import { LogService } from '../log.service';

import { Study } from "../../interfaces/study";

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {
	userLoggedIn: boolean = false;
	study: Study = {studyName: "Willkommen"} as Study;
	loginForm: FormGroup;

	constructor(
		private router: Router,
		public formBuilder: FormBuilder,
		private afAuth: AngularFireAuth,
		public alertCtrl: AlertController,
		public modalCtrl: ModalController,
		public log: LogService,
		// public msgService: MessagingProvider,
		public profile: ProfileService
	) {
		let destroy$: Subject<boolean> = new Subject<boolean>();
		afAuth.authState.subscribe(user => {
			if (!user) {
				console.log('not logged in' )
				this.userLoggedIn = false
				return;
			}
			this.userLoggedIn = true
			this.log.currentStudySubject.pipe(takeUntil(destroy$))
				.subscribe(res => {
					if(res) {
						this.study = res
						destroy$.next(true)
					}
			})
		});

		this.loginForm = formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.compose([Validators.minLength(6), 
				Validators.required])]
			// email: ['foo@bar.com', [Validators.required, Validators.email]],
			// password: ['foobar', Validators.minLength(6)] 
		});
	}


	public emailLogin(){
		// TODO move to profile service?
		this.afAuth.signInWithEmailAndPassword(
			this.loginForm.value.email, this.loginForm.value.password)
			.then( () => { 
				this.router.navigate(['/survey']) 
			}).catch((error) => {
				// console.warn( error.code)
				let errorMessage = "Something went wrong, sorry!"
				if (error.code) {
					switch (error.code) {
							// login cases
						case 'auth/user-not-found': {
							// errorMessage = "We didn't recognize that email/password. <br><br> Please use 'Create Account' to create a new account."
							errorMessage = 'Wir konnten diese email/passwort nicht zuordnen. <br><br>Bitte nutzen Sie die Option "Neuen Benutzer anlegen" um einen neuen Benutzer anzulegen'
							break;
						}
						case 'auth/wrong-password': {
							// errorMessage = "We didn't recognize that email/password. <br><br> Please use 'Create Account' to create a new account."
							errorMessage = 'Wir konnten diese email/passwort nicht zuordnen. <br><br>Bitte überprüfen Sie Ihre Eingaben. Sollten Sie die App zum ersten mal nutzen wählen Sie die Option "Neuen Benutzer anlegen"'
							break;
						}
						case 'auth/user-disabled': {
							errorMessage = "Your account has been disabled. Please contact the project coordinator if you think this is wrong"
							break;
						}
					}
					let errorAlert = this.alertCtrl.create({
						message: errorMessage,
						buttons: [ { text: "Ok", role: 'cancel' } ]
					}).then( res => { res.present(); })
				} else { // error but no error code. Should not happen
					console.warn( this.loginForm.value )
				}
			})
	}


	public resetPassword(): Promise<any> {
		return this.afAuth.sendPasswordResetEmail(this.loginForm.value.email)
		.then( user  => {
			this.alertCtrl.create({
				message: "We just sent a reset link to " + this.loginForm.value.email,
				buttons: [ { text: "Ok", role: 'cancel' } ]
			}).then( res => { res.present(); })
		}, (error) => {
			var errorMessage: string = error.message;
			this.alertCtrl.create({
				message: errorMessage,
				buttons: [ { text: "Ok", role: 'cancel' } ]
			}).then( res => { res.present(); })
		});
	}


	public createAccount(): Promise<any> {
		// better to do after creating account
		if(this.loginForm.valid){
			return this.afAuth.createUserWithEmailAndPassword(
				this.loginForm.value.email, this.loginForm.value.password)
				// .then( res  => console.log( res ))
				.then( () => {
					this.presentOnboardingModal()
				})
				.catch((error) => {
					var errorMessage: string = error.message;
					let errorAlert = this.alertCtrl.create({
						message: errorMessage,
						buttons: [ { text: "Ok", role: 'cancel' } ]
					}).then( res => { res.present(); })
				});
		} else {
			console.warn( this.loginForm.value )
		}
	}
	

	public signOut(){
		this.profile.signOut()
	}


// https://ionicframework.com/docs/api/modal
	async presentOnboardingModal(){
		console.log( 'home page presents onboarding' )
		const onboardingModal = await this.modalCtrl.create({
			component: OnboardingPage,
			componentProps: {
				doneButtonText: "WEITER" // NEXT
			}
		})

		onboardingModal.onDidDismiss().then( (data) => {
			if(data){ 
				// console.log( data )	
			}
			this.presentNotificationsModal()
		})

		return await onboardingModal.present() 
	}


	async presentNotificationsModal(){
		console.log( "home page presents notifications model" )
		const notificationsModal = await this.modalCtrl.create({
			component: NotificationsPage
		})

		notificationsModal.onDidDismiss().then( (data) => {
			// use a timeout to let settings update before the survey
			// page gets called
			setTimeout( ()=> {
				this.router.navigate(['/survey']) 
			}, 70)
		})

		return await notificationsModal.present() 
	}

}


