import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
// import { Injectable } from '@angular/core';

export interface CanComponentDeactivate {
	canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

// @Injectable({
// 	providedIn: 'root',
// })

export class CanDeactivateGuard implements
CanDeactivate<CanComponentDeactivate> {
	canDeactivate(component: CanComponentDeactivate) {
		console.log( "guard fires" )
		return component.canDeactivate && component.canDeactivate();    
	}
}
