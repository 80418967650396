import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, IonContent } from '@ionic/angular';
import { AngularFireAuth } from "@angular/fire/auth";
import { Observable } from "rxjs";
import { take, map, tap } from 'rxjs/operators';

import { CanComponentDeactivate } from '../can-deactivate.guard'

import { OnboardingPage } from '../onboarding/onboarding.page';
import { NotificationsPage } from '../notifications/notifications.page';

import { LogService } from "../log.service";
import { MessagingService } from "../messaging.service";
import { ProfileService } from "../profile.service";
import { UtilitiesService } from "../utilities.service";

import { QuestionBlock } from "../../interfaces/questionBlock";
import { Study } from "../../interfaces/study";


@Component({
  selector: 'app-survey',
  templateUrl: './survey.page.html',
  styleUrls: ['./survey.page.scss'],
})
export class SurveyPage implements CanComponentDeactivate {
	public study: Study
	public currentBlock: QuestionBlock;
	public currentBlockIndex: number;

	private isTimeLocked: boolean = false;
	private nextLogUpdate: number = 8;
	private freezeLockOn: boolean = false; // lock the lock (solves async race condition)
	// private lastLogUpdate: number = 24;
	private isFirstEntry: boolean = false; // true iff the user has no history
	private entriesRemaining: number = 0;
	private isRandomTimes: boolean = false;

	isDataLoaded: boolean = false;
	isEntryComplete: boolean = false;
	isEntryLocked: boolean = false; // used by the navguard

	isOnboardingComplete: boolean = false;
	isShowingOnboardingModal: boolean = false;
	isShowingNotificationsModal: boolean = false;
	isSingleBlockSurvey: boolean = false;

	@ViewChild(IonContent) content: IonContent;

	// private subscriptions = [] // no longer used, see note in constructor

  constructor(
		private router: Router,
		public afAuth: AngularFireAuth,
		public alertCtrl: AlertController,
		public modalCtrl: ModalController,
		public log: LogService,
		public msg: MessagingService,
		public profile: ProfileService,
		public utils: UtilitiesService
	) {
		console.log( 'hello survey page' )

		// set up the study/question blocks
		let studySub = log.currentStudySubject.subscribe(res => {
			if(res){
				this.isDataLoaded = true;
				this.currentBlock = res.questionBlocks[0]
				this.currentBlockIndex = 0;
				// The next line should be handled in the time lock
				// function. leave it here as a safety
				if(this.isTimeLocked){ this.currentBlockIndex = -1 } 
				// bind remaining variables
				this.study = res
				this.isSingleBlockSurvey = res.questionBlocks.length === 1
			}
		})

		// check profile/onboarding
		// ALSO check time to lock/unlock screen
		let profileSub = profile.userSettings.subscribe(async res => {
			if(!res) return
			this.isOnboardingComplete = res.isOnboardingComplete
			// if onboarding is not complete, finish it now
			if (!this.isOnboardingComplete) {
				// modals are non-blocking. Frustrating. Fake it by using
				// boolean flags
				// do we need to show the onboarding modal?
				if(this.study || res.studyID){
					// Study ID is registered (and probably their names as well)
					// show notifications modal
					if(! this.isShowingNotificationsModal && ! this.isShowingOnboardingModal){
						console.log( "showing notification modal")
						await this.presentNotificationsModal()
					} 
				} else { // yes, we need to present the onboarding modal
					if(! this.isShowingOnboardingModal) {
						console.log( "showing onboarding modal" )
						await this.presentOnboardingModal()
					}
				}
			} else { // onboarding is complete. Set up survey page
				let schedule = res.notificationSchedule
				if(res.isRandomTimes){
					// error checking
					if(! Array.isArray(res.randomNotificationSchedule)){
						console.warn( "user settings incomplete: no random schedule" )
						console.warn(res['randomNotificationSchedule'])
					}
					if(this.study && this.study['frequency']){
						if(res.randomNotificationSchedule.length != this.study['frequency']){
							console.warn( "user settings wrong: random schedule has",
								res.randomNotificationSchedule.length, "times, study expects",
								this.study.frequency,'times')
						}
					}
					schedule = res.randomNotificationSchedule
					this.isRandomTimes=true
					// compute number of remaining entries
					let target = this.utils.localToServerTime(new Date().getHours())
					let foo = schedule.sort((a, b) => a - b)
						.filter(time => time > target)
					this.entriesRemaining = foo.length 
					// console.log( target, this.entriesRemaining )
				}
				// nextLogUpdate is shows on web page if the page is locked
				this.nextLogUpdate =
					this.utils.serverToLocalTime(this.utils.findNextTime(schedule))
				// check/set timelock
				this.checkTimeLock({schedule: schedule})
			}
		})

		// set up the current entry
		// It is possible that the current entry is locked.
		// If so, we simply block the page using the time-lock
		// based blocking. This is simpler than blocking all of
		// the input components, better to just not show them.
		// The log subject prevents a locked entry from updating,
		// so I do not need to guard against that here.
		let curEntrySub = log.currentEntrySubject.subscribe(res => {
			// todo: reset background autosave timer ?
			if(!res) return
			// console.log(  res.answers.length, res.answers )
			if(!this.study) {
				console.warn( "log gave result before study loaded" )
				return
			}
			if(res.isLocked){
				// console.warn( "log loads locked entry" )
				// note: timelock is separate from isEntryLocked
				this.isEntryLocked = true
				// hard-lock page using time-lock
				this.checkTimeLock({hardLock: true})
			} else {
				this.isEntryLocked = false
			}
			this.isEntryComplete = res.isComplete
			if(res.answers && res.answers.length === 0){
				this.study.questionBlocks.forEach(block => {
					block.isComplete = false
				})
			}
		})

		log.isFirstEntrySubject.subscribe(res => {
			if(res) { // subject is a boolean
				this.isFirstEntry = true
				// undo timelock stuff
				this.isTimeLocked = false
				if(this.currentBlockIndex == -1) {
					this.currentBlockIndex = 0
				}
			}
		})


		// subscription guard
		afAuth.authState.subscribe(user => {
			if(! user){
					this.router.navigate(['/home']) 
			} else {
			}
		})
		
		// Used to create an array of subscriptions on page creation, 
		// 	which is destroyed on navigating away. However, if the auth guard
		// kicks us out, the page is not recreated when you return to it. So
		// on return (after log-in), the subjects do not load.
		// So cancel the idea.
		// this.subscriptions.push(studySub, curEntrySub, profileSub)
		// this.subscriptions.forEach( sub => sub.unsubscribe() )
  } // end constructor


	private checkTimeLock(params){
		// note: timelock is separate from isEntryLocked
		// never time-lock the first entry
		if(this.isFirstEntry){	
			this.isTimeLocked = false
			return
		}
		/************************************/
		// console.log( "time lock turned off" )
		// this.isTimeLocked = false
		// return
		/************************************/
		if(params.hasOwnProperty('hardLock')){
			this.freezeLockOn = true
		}
		if(this.freezeLockOn){
			this.isTimeLocked = true
		}
		if(params.hasOwnProperty('schedule')){
			if(!this.freezeLockOn){
				this.isTimeLocked = this.utils.isTimeWindowClosed(params.schedule)
			}
		}
		if(this.isTimeLocked){ this.currentBlockIndex = -1 } 
	}


	ngAfterViewInit(){ }


	/**
	 * ionViewCanEnter
	 * Auth guard the page. Only logged in users can nav to it
	 * Use take(1).map() to convert the subscription to an
	 * observable.
	 * */
	ionViewCanEnter(): Observable<boolean>{
		return this.afAuth.authState.pipe(
			// tap(res => console.log( res )),
			map(res => res ? true : false ),
			take(1)
		)
	}

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
		console.log( "can deactivate log checked" )
		if(this.isEntryLocked) {
			return(true)
		}
	}

	ionViewWillLeave(){
		this.log.updateFirebase()
	}


	submitAnswers() {
		// leaving the page triggers the save
		this.log.lockCurrentEntry()
		this.msg.requestNotificationDocToFB()
		// this.isEntryLocked = true
		this.router.navigate(['/history']) 
	}

	navButton(qblock: QuestionBlock, index: number){
		// console.log( qblock )
		this.log.updateFirebase()
		if(qblock.blockID === this.currentBlock.qid) return
		this.currentBlock = qblock
		this.currentBlockIndex = index
	}

	prevBlock(){
		this.log.updateFirebase()
		this.currentBlockIndex--
		if(this.currentBlockIndex < 0) this.currentBlockIndex = 0
		this.currentBlock = this.study.questionBlocks[this.currentBlockIndex]
		this.content.scrollToTop()
	}

	nextBlock(){
		// console.log( "next block" )
		this.log.updateFirebase()
		this.currentBlockIndex++
		if(this.currentBlockIndex === this.study.questionBlocks.length)
			this.currentBlockIndex--
		this.currentBlock = this.study.questionBlocks[this.currentBlockIndex]
		// console.log( this.currentBlockIndex )
		// console.log( this.currentBlock)
		this.content.scrollToTop()
	}



	/**
	 * openOnboarding
	 * Opens the onboarding modal
	 * Useful if i.e. study is not found
	 * */
	async presentOnboardingModal(): Promise <any> {
		console.log( "survey page presents onboarding modal" )
		this.isShowingOnboardingModal = true
		const onboardingModal = await this.modalCtrl.create({
			component: OnboardingPage,
			componentProps: {
				// doneButtonText: "WEITER" // from the homepage version of this function
				// source: 'surveyPage' ;; it's in the original, not sure why it is needed
			}
		})

		onboardingModal.onDidDismiss().then( (data) => {
			console.log( "survey page done with onboarding modal" )
			this.isShowingOnboardingModal = false
			if(data){ 
				// console.log( data )	
			}
			if(!this.isOnboardingComplete)  this.presentNotificationsModal()
		})

		return await onboardingModal.present() 
	}


	/**
	 * presentNotificationsModal
	 * Opens the notifications modal
	 * Useful if i.e. notification settings need to change
	 * */
	async presentNotificationsModal(): Promise <any>{
		console.log( "survey page presents notifications modal" )
		this.isShowingNotificationsModal = true
		const messagingModal = await this.modalCtrl.create({
			component: NotificationsPage,
			componentProps: {
				// source: 'surveyPage' ;; it's in the original, not sure why it is needed
			}
		})

		messagingModal.onDidDismiss().then( (data) => {
			console.log( "survey page done with notification modal" )
			this.isShowingNotificationsModal = false
		})

		return await messagingModal.present() 
	}
}



/*** arkiv */
	// async displayAllDone(){
	// 	// TODO! All I've done is disable the alert. Perhaps remove function
	// 	// entirely
	// 	// console.log( "displayAllDone called", this.alertHack )
	// 	if(this.alertHack) return
	// 	if(this.isShowingNotificationsModal) return
	// 	this.alertHack = true
	// 	const alert = await this.alertCtrl.create({
	// 		header: "Herzlichen Dank!",
	// 		message: "Möchten Sie ihre Antworten bearbeiten oder sind Sie die Eingabe beenden?",
	// 		buttons: [{
	// 			text: 'Bearbeiten',
	// 			role: 'cancel',
	// 			handler: () => { }
	// 		}, {
	// 			text: 'Beenden',
	// 			handler: () => {
	// 				this.router.navigate(['/history']) 
	// 			}
	// 		}
	// 		] });
	// 	// await alert.present()
	// }



