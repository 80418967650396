import { Component, OnInit, ElementRef, EventEmitter, HostListener, Output, OnDestroy, ViewChild } from '@angular/core';
import { Subscription }   from 'rxjs';
import { nest } from 'd3-collection';
import { timeFormat } from 'd3-time-format';
import { timeDay, timeDays, timeMonday, timeMonth, timeMonths } from 'd3-time';
// import * as d3 from 'd3'; selection, transition, drag
import { select } from 'd3-selection';
// import { selectAll } from 'd3-selection';
import { transition } from 'd3-transition';
import { drag } from 'd3-drag';
import { SvgUtilsService } from "../svg-utils.service";
import { LogService } from "../log.service";


/**
 * Plots the calendar chart
 *
 * Week starting date is set by the timeMonday interval
 * use timeMonday or timeSunday for Sunday
 *     timeMonday for Monday
 * */


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
})
export class CalendarComponent implements OnInit, OnDestroy {
	ngOnInit() {}

	logSubscription: Subscription;
	targetDateSub: Subscription;
	@Output() selectedDate =  new EventEmitter<number>();

	@ViewChild('imagePane') imagePane: ElementRef

	public dataLoaded: boolean = false; // toggles the loading spinner
	// Data related stuff
	private logSummaries; //: Array<any>;
	private logKeys: Object = {};
	private dateFormat = timeFormat("%Y-%m-%d");
	// private earliestTimestampInData = new Date().getTime();
	private targetDuration: number = 21;
	private targetEndDate: Date = new Date(); // the day the logging should stop
	private targetFrequency: number = 4;
	// private scrollingTimeout = null;
	private centerDate = new Date();
	private WEEKSTOSHOW = 4; // weeks before/after center date to display
	private TDUR = 0; // transition duration, initially zero. Reset when scrolling

	// the SVG elements
	private svg;
	private svgContent;
	private bgRect;
	private dateBoxGroup;
	// private topChevron;
	// private bottomChevron;
	
	constructor(
		public logService: LogService,
		public svgUtils: SvgUtilsService
	) {
		console.log('Hello Calendar Component');
		this.logSubscription = logService.allEntriesSubject
			.subscribe(logArray => {
				// console.warn( "got new log entries", logArray )
				if(! Array.isArray(logArray)) return
				if(logArray.length === 0){
					// console.log( "no log entries" )
				}
				this.dataLoaded = true
				this.setData(logArray)
				this.updateCalendar('log subscription')
			})
		this.targetDateSub = logService.currentStudySubject
		.subscribe(res => {
			if(res){
				this.targetDuration = res.duration
				this.targetFrequency = res.frequency
				this.updateCalendar('target date subject')
			}
			})
  }

	@HostListener('window:resize') windowResize(){
		if(this.logSummaries) { this.updateCalendar('resize') }
	}

	// Set up SVG stuff
	ngAfterViewInit(){
		this.svg = select(this.imagePane.nativeElement)
			.append('svg');

		this.svgContent = this.svg.append('g')

		this.bgRect = this.svgContent.append("rect")
			.classed('calendar-background', true)
			.attr('fill','grey')

		this.dateBoxGroup = this.svgContent.append('g')
		setTimeout( ()=> {
			this.updateCalendar('afterviewinit')
		},10)

		// No need for chevrons. Kept in case the need returns
		// let chevronPath = "M 2.5,0 L 3.5,-1 L 4.5,0";
		// this.topChevron = this.svgContent.append('path')
		// 	.classed('calendar-chevron', true)
		// 	.attr("d", chevronPath)
		// 	.attr("fill", "none")
		// 	.attr("stroke", "#AAA")
		// 	.attr("stroke-width", 0.20)
		// 	.attr("stroke-linecap", "round")
		// 	.call(drag()
		// 		.on("start", () => this.scrollCalUp())
		// 	);
		// this.bottomChevron = this.svgContent.append('path')
		// 	.classed('calendar-chevron', true)
		// 	.attr("d", chevronPath)
		// 	.attr("fill", "none")
		// 	.attr("stroke", "#AAA")
		// 	.attr("stroke-width", 0.20)
		// 	.attr("stroke-linecap", "round")
		// 	.call(drag()
		// 		.on("start", () => this.scrollCalDown())
		// 	);
	}

	ngOnDestroy(){
   // prevent memory leak when component destroyed
    this.logSubscription.unsubscribe();
		this.targetDateSub.unsubscribe();
	}


	setData(data){
		// console.log( "set data called" )
		// console.log( data )
		if(!Array.isArray(data)) return
		if(data.length === 0) return
		// Set target end date using logService
		this.targetEndDate = new Date(this.logService.calcLastDay(data))
		
		// pristine is TRUE if the check in has never been
		// touched by a user. The log should only show
		// log entries which have been modified by someone.
		data = data.filter( val => ! val.pristine )
		this.logSummaries = nest()
			.key(d => {
				let date = new Date(d.timestamp)
				return this.dateFormat(date)
			})
			.rollup(d => {
				// d is all log entries which match date "d"
				// console.log( d )
				// find index of most recent entry
				let index = d.reduce((imax, x, i, arr) => {
					return x.timestamp > arr[imax].timestamp ? i : imax}, 0);
				// set key to that value
				let date = new Date(d[index].timestamp)
				this.logKeys[this.dateFormat(date)] = d[index]['$key']
				// count number of log entrires for that day
				let numEntries = d.length
				// scale as fraction of possible
				// TODO! this loop can trigger before the log subject
				// returns the current study (and sets the study frequency
				// leading to "bad" coloring.
				if(this.targetFrequency <= 0){
					// console.warn( "bad study frequency, defaulting to 4" )
					this.targetFrequency = 4
				}
				let scale = 100/this.targetFrequency
				return scale * numEntries
				// DEBUG: return random number. Helpful for color scale
				// return Math.round(Math.random()*5) * 20

			})
			.object(data)
		// console.log( this.logSummaries )
		// console.log( this.logKeys )
	}


	////////////////////////////////////////////////////////
	// (re-)draws the calendar
	// around the (new) centerDate
	private updateCalendar(caller){
		// console.log( "calling update calendar", caller )
		// make sure page has rendered
		if(! this.imagePane || this.imagePane.nativeElement.offsetWidth === 0){
			// console.log( 'no image pane', this.imagePane )
			if(this.imagePane){
				// console.log( this.imagePane.nativeElement )
			}
			return 0;
		}
		if(! this.logSummaries) { 
			// console.log( 'no log' )
			return 
		}
		// console.log( this.logSummaries )

		// date vars
		// set start, end to center the data
		// const startDate = timeMonday.offset(timeMonday(this.centerDate),
		// 	-1 * this.WEEKSTOSHOW);
		// const endDate   = timeMonday.offset(timeMonday(this.centerDate), 
		// 	this.WEEKSTOSHOW);
		// set start, end to show full data
		let logDates = Object.keys(this.logSummaries).sort()
		const startDate = timeMonday(new Date(logDates[0]))
		let endDate = timeMonday.offset(startDate, this.WEEKSTOSHOW * 2)

		const daysArray = timeDays(startDate, endDate)

		// svg settings vars
		const cellSize: number = 35;
		const width: number = this.imagePane.nativeElement.offsetWidth
		// height in units of cellsize: (WTS + chevron) * 2
		//     where a chevron has height 2 cellsSize
		//     With chevrons gone, chevron height is set to zero
		const height: number = cellSize * (this.WEEKSTOSHOW + 0) * 2
		const trans = transition().duration(this.TDUR);

		// set the svg object properties
		// NOTE! we add a 6 px margin to the height, and 
		// translate the svg content down by 3
		this.svg.attr("width", width)
			.attr("height", height + 6)

		this.svgContent.attr("transform", "translate(" +
			Math.max(((width - cellSize * 7) / 2), 15) + ",3)");
		// + cellSize + ")");

		this.bgRect.attr('width', cellSize * 7)
			.attr("height", this.WEEKSTOSHOW * 2 * cellSize)
			.attr('x', 0)
			.attr('y', 0)

		// this.topChevron.attr("transform",
		// 	"scale(" + cellSize + ") translate(0,-0.4)")
		// this.bottomChevron.attr("transform",
		// 	"scale(" + cellSize + ") rotate (180) translate(-7,-" + 2 * this.WEEKSTOSHOW + ".4)")

		// JOIN boxes data
		var rect=this.dateBoxGroup.selectAll("rect")
			.data(daysArray, d => this.dateFormat(d) ) 

		// ENTER new boxes at correct x,y location
		let today = new Date()
		let targetBox // save the targetBox so we can add it last, to keep 
		//               its border on top.
		rect.enter().append("rect")
			.attr('class', (d, i, q)  =>{
				let cls = "day-cell";
				if( this.dateFormat(d) === this.dateFormat(this.targetEndDate) ){ 
					cls = cls + " target-box"
					targetBox = q[i] // save target box here
				} else if (this.dateFormat(d) === this.dateFormat(today) ){
					cls = cls + " selected-box"
					let thisBox = q[i]
					thisBox.parentNode.append(thisBox)
				}
				return cls;
			})
			.attr("fill", (d) => {
				var fillval = this.logSummaries[this.dateFormat(d)] 
				if(fillval === undefined) {return 'white';}
				return (this.svgUtils.getColor(this.logSummaries[this.dateFormat(d)]));
			})
			.attr("width", cellSize)
			.attr("height", 0)
			.attr("x", (d) => {
				return ( timeDay.count(timeMonday(d),d) ) * cellSize;
			})
			.attr("y", (d) => {
				if(timeMonday.count(d, startDate) < 0){
					// adding at the bottom, initial height needs to be set high
					return (timeMonday.count(timeMonday(startDate), d) +1 ) * cellSize;
				} else { // adding at the top, initial height is ok
					return (timeMonday.count(timeMonday(startDate), d) ) * cellSize;
				}
			})
			.attr('rx',1).attr('ry',1)
			.call(drag().on("start", (d, i, q) =>{
				// change which box is marked in svg/display
				// selectAll('.selected-box').classed('selected-box',false)
				// let thisBox = q[i]
				// select(thisBox).classed("selected-box",true)
				// thisBox.parentNode.append(thisBox)
				// ------------------------------
				//      EMITTER IS HERE
				// ------------------------------
				// Emit the chosen date, or the entries found on that date
				// if ( this.logKeys[this.dateFormat(d)] ){
				// 		this.selectedDate.emit( this.logKeys[this.dateFormat(d)] )
				// } else { this.selectedDate.emit(d.getTime()) }
			})
			)
			.transition(trans)
			.attr("height", cellSize)
			.attr("y", (d) => {
				return (timeMonday.count(timeMonday(startDate), d) ) * cellSize;
			})
		// ending y position is timeMondayCount * cellSize
		// Lastly, add target box, so it is always on top
		if(targetBox && targetBox.parentNode) { targetBox.parentNode.append(targetBox) }
		

		// UPDATE existing boxes 
		rect.transition(trans)
			.attr("x", (d, i, q) => {
				let thisBox = q[i]
				if( select(thisBox).classed("selected-box") === true)
					thisBox.parentNode.append(thisBox)
				return ( timeDay.count(timeMonday(d),d) ) * cellSize;
			})
			.attr("y", (d) => {
				return timeMonday.count(timeMonday(startDate), d) * cellSize;
			})
			.attr("fill", (d) => {
				var fillval = this.logSummaries[this.dateFormat(d)] 
				if(fillval === undefined) {return 'white';}
				return (this.svgUtils.getColor(this.logSummaries[this.dateFormat(d)]));
			})


		// EXIT old date boxes
		rect.exit()
			.transition(trans).attr("height",0)
			.attr("y", (d) => {
				if(timeMonday.count(d, startDate) < 0){
					// we are deleting from the botton. 
					return (timeMonday.count(timeMonday(startDate), d)) * cellSize;
				} else { // delete from top
					return (timeMonday.count(timeMonday(startDate), d) +1) * cellSize;
				}
			})
			.remove()


		// test-- adding dates to the calendar
		let dates=this.dateBoxGroup.selectAll("text")
			.data(daysArray) 
		let dayFormat = timeFormat("%d")
		dates.enter().append("text")
			.text(d => dayFormat(d))
			.attr('class', 'date-text')
			.attr("x", (d) => {
				return ( timeDay.count(timeMonday(d),d) ) * cellSize;
			})
			.attr("y", (d) => {
				return (timeMonday.count(timeMonday(startDate), d) ) * cellSize;
			})
		.attr("dx", 5)
		.attr("dy", 15)

		dates.exit().remove()
	


		// array of month starting dates, centered on centerDate
		var monthStarts = timeMonths(timeMonth.offset(this.centerDate,-2),
			timeMonth.offset(this.centerDate,+2))
		// console.log( monthStarts )

		// add the lines around each month
		var monthLines = this.svgContent.selectAll(".monthLine")
			.data(monthStarts.filter((d) =>{ return d > startDate && d < endDate}),
				(d) => { return this.dateFormat(d); })

		monthLines.enter()
			.append("path")
			.attr('class', "monthLine")
			.attr("fill", "none")
			.attr("stroke", "#444")
			.attr("stroke-width", 2)
			.attr("opacity", 1e-6)
			.transition(trans).attr("opacity", 1)
			.attr("d", pathMonth)

		monthLines.transition(trans)
			.attr("d", pathMonth);

		monthLines.exit()
			.transition(trans).attr("opacity", 1e-6)
			.remove()

		// add month labels on the left
		var monthLabels = this.svgContent.selectAll(".monthLabel")
			.data(monthStarts.slice(0,3), (d) => this.dateFormat(d) )

		// old way, enabled scrolling
		// .data(monthStarts.filter((d) =>{ 
		// 	// adding two weeks for the top, subtracting two for the bottom
		// 	return timeMonday.offset(d, 2)> startDate && 
		// 		timeMonday.offset(d, 1)< endDate}), 

		monthLabels.enter()
			.append("text")
			.attr("transform", "rotate(90)")
			.text((d) => {
				var locale = "de-de"; //"en-us"; // let's internationalize it already...
				return d.toLocaleString(locale, {month: "long"});
				// return d.toLocaleString(locale, {month: "short"});
			})
			.attr("class","monthLabel")
			.attr("font-family", "sans-serif")
			.attr("font-size", 16)
			.attr("text-anchor", "start")
			.style("opacity", 1e-6)
			.attr("y", -7.2 * cellSize) 
			.attr("x", function (date) {
				var weekCount = timeMonday.count(startDate, date); 
				return (weekCount + 0.2) * cellSize
			})
			.merge(monthLabels)
			.transition(trans)
			.style("opacity", 1)
		// remember, x and y position are rotated 90 degrees by the transform
			.attr("y", -7.2 * cellSize) 
			.attr("x", function (date) {
				var weekCount = timeMonday.count(startDate, date); 
				return (weekCount + 0.2) * cellSize
			})
		monthLabels.exit().remove();

		// add year label on the left
		// this.svgContent.append("text")
		// 	.text(startDate.getFullYear());

		// t0 is the first day of the month
		function pathMonth(t0) {
			// console.log( t0 )
			var	d0 = t0.getDay(),
				w0 = timeMonday.count(startDate, t0),
				w1 = timeMonday.count(t0, endDate);
			// console.log( dateFormat(t0), w0, w1 )
			if(w1 === 0) { // adding at bottom
				return "M " + d0 * cellSize + "," + w0 * cellSize;
				// return "M " + d0 * cellSize + "," + w0 * cellSize + " H " + 7 * cellSize;
			}
			if(d0 === 0){
				return "M 0," +  w0 * cellSize + "H" + 7 * cellSize;
			}
			return "M 0," + (w0 + 1) * cellSize 
				+ "H" + d0 * cellSize + "V" + w0 * cellSize
				+ "H" + 7 * cellSize;
		}
	}

}


	//////////////////////////////////////////////////////////
	//// scrolls calendar up/down by one week
	//// also sets the transition duration. This hack allows
	//// the initial entry to be instant, but smooth for scrolling.

	//scrollCalUp(){
	//	this.TDUR = 250
	//	if(this.scrollingTimeout === null){
	//		this.centerDate = timeDay.offset(timeDay(this.centerDate), 7);
	//		this.updateCalendar();
	//		this.scrollingTimeout = setTimeout(() => {this.scrollingTimeout = null}, 
	//			this.TDUR + 50)
	//	}
	//}

	//// could require more data
	//scrollCalDown(){
	//	this.TDUR = 250
	//	if(this.scrollingTimeout === null){
	//		this.centerDate = timeDay.offset(timeDay(this.centerDate), -7);
	//		this.updateCalendar();
	//		// for future: if calendar only displays part of data
	//		// if(timeMonday.offset(this.centerDate, -1 * this.WEEKSTOSHOW) < 
	//		// 	timeMonday(this.earliestTimestampInData) ){
	//		// 		this.logService.fetchMoreHistory()
	//		// }
	//		this.scrollingTimeout = setTimeout(() => {this.scrollingTimeout = null}, 
	//			this.TDUR + 50)
	//	}
	//}
	
