import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

interface menuPage {
	title: string, 
		url: string,
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

	anonPages: menuPage[] = [
      { title: 'Log in', url: 'home' },
      { title: 'Datenschutz', url: 'terms'},
	]

	knownUserPages: menuPage[] = [
      { title: 'Daten eingeben', url: 'survey'},
      { title: 'Übersicht', url: 'history' },
      { title: 'Hintergrund', url: 'settings' },
      { title: 'Datenschutz', url: 'terms'},
		//  remove the logout page
      // { title: 'Log out', url: 'home' },
		//// { title: 'Welcome', url: HomePage },
      //// { title: 'Impressum', url: 'Impressum' },
    ];

  pages: menuPage[] = []


	constructor(
		afAuth: AngularFireAuth,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {

		afAuth.authState.subscribe( user => {
			if(user) {
				this.pages = this.knownUserPages
				// console.warn("remove log-out page")
			} else {
				this.pages = this.anonPages
				// console.warn("remove log-out page")
			}
		})

    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
			// cordova specific commands:
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();
    });
  }
}


